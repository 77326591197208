import * as _ from "lodash";
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";

import "./MonthlyReport.scss";
import { getCompanyById, getUserCompanies } from "../../api/requests/companies";
import { addWorkerReport } from "../../api/requests/worker-report";
import DateCarousel from "../date-carousel";
import EmployeesList from "./employees-list";
import EmployeeReport from "./employee-report";
import CompaniesList from "./companies-list";
import { ReactComponent as ArrowBackIcon } from '../../shared/icons/arrow-back-icon.svg';
import { Role } from "../../shared/constants";
import Loader from "../loder";

const MonthlyReport = (props) => {
  const { user } = props;
  const { t } = useTranslation();
  const [userCompanies, setUserCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [employees, setEmployees] = useState([]);
  const [currentMonth, setCurrentMonth] = useState({
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear()
  });
  const [selectedReport, setSelectedReport] = useState(null);//refresh from BE only. only for admin
  const [selectedEmployee, setSelectedEmployee] = useState(null)
  const [exchangeRate, setExchangeRate] = useState(3.1);
  const [showLoader, setShowLoader] = useState(false)

  useEffect(() => {
    setShowLoader(true);
    getUserCompanies(true)
      .then((result) => {
        if (result.length === 1 && user.role !== Role.admin) {
          getCompany(result[0]?.id);
        }

        setUserCompanies(_.sortBy(result, "company_name"));
      })
      .finally(() => {
        setShowLoader(false);
      });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!selectedCompany) {
      setEmployees([]);
      return;
    }

    const updatedCompanyUsers = selectedCompany.companyUsers?.map(getEmployeeViewObject);

    setEmployees(selectedCompany ? updatedCompanyUsers : []);

    if (selectedCompany.companyUsers.length === 1) {
      setSelectedEmployee(updatedCompanyUsers[0])
    }
    // eslint-disable-next-line
  }, [selectedCompany]);

  useEffect(() => {
    if (!selectedCompany)
      return;

    const report = _.find(selectedCompany.reports, (report) => report.month === currentMonth.month && report.year === currentMonth.year);

    if (!report) {
      setShowLoader(true);
      addWorkerReport({
        company_id: selectedCompany.id,
        year: currentMonth.year,
        month: currentMonth.month
      }).then((createdReport) => {
        const company = { ...selectedCompany, reports: [...selectedCompany.reports, createdReport] };

        if (selectedEmployee) {
          const companyUserIndex = _.findIndex(company.companyUsers, { id: selectedEmployee.id });
          company.companyUsers.splice(companyUserIndex, 1, selectedEmployee);
        }

        const index = _.findIndex(userCompanies, { id: selectedCompany.id });
        setSelectedCompany(company);
        const companies = [...userCompanies];
        companies.splice(index, 1, company);
        setUserCompanies(companies);
      })
        .finally(() => {
          setShowLoader(false);
        });
    } else {
      setSelectedReport(report);
    }
    // eslint-disable-next-line
  }, [selectedCompany, currentMonth]);

  const getEmployeeViewObject = (companyEmployee) => {
    const monthlyReport = companyEmployee.reports?.find((employeeReport) => employeeReport.report.month === currentMonth.month && employeeReport.report.year === currentMonth.year);

    let itemsForCalculation = [];
    let total = 0;
    let monthlyExchangeRate = exchangeRate;

    if (monthlyReport) {
      itemsForCalculation = _.filter(monthlyReport.reportData, (reportItem) => reportItem.id && !reportItem.sick_day && !reportItem.vacation && reportItem.total_amount > 0);
      total = Math.floor(_.sumBy(itemsForCalculation, "total_amount") * 100) / 100;
      monthlyExchangeRate = monthlyReport?.submitted_by_manager_at ? monthlyReport?.report?.exchange_rate : exchangeRate
    }
    const viewEmployee = {
      ...companyEmployee,
      totalCost: total,
      totalFee: Math.floor(total * selectedCompany.companyTerms.report_fee) / 100,
      days: itemsForCalculation.length,
    }

    viewEmployee.total = Math.floor(( viewEmployee.totalCost - viewEmployee.totalFee ) * 100) / 100;
    viewEmployee.totalCostNis = Math.floor(viewEmployee.totalCost * monthlyExchangeRate * 100) / 100
    viewEmployee.totalFeeNis = Math.floor(viewEmployee.totalFee * monthlyExchangeRate * 100) / 100;
    viewEmployee.totalNis = Math.floor(( viewEmployee.totalCostNis - viewEmployee.totalFeeNis ) * 100) / 100;

    viewEmployee.totalCostView = viewEmployee.totalCost ? `$${viewEmployee.totalCost}/₪${viewEmployee.totalCostNis}` : 0;
    viewEmployee.totalFeeView = viewEmployee.totalFee ? `$${viewEmployee.totalFee}/₪${viewEmployee.totalFeeNis}` : 0;
    viewEmployee.totalView = viewEmployee.total ? `$${viewEmployee.total}/₪${viewEmployee.totalNis}` : 0;

    viewEmployee.firstnameView =
      <div className="clickable-block"
           onClick={() => {
             if (user.role === Role.admin)
               return;

             setSelectedEmployee(viewEmployee);
           }}>{viewEmployee.user.firstname}</div>
    viewEmployee.identity_number = viewEmployee.user.identity_number;

    return viewEmployee;
  }

  const employeeUpdate = (updatedEmployee) => {
    const updatedEmployees = [...employees];
    const selectedEmployeeIndex = _.findIndex(employees, { id: selectedEmployee.id });
    updatedEmployees.splice(selectedEmployeeIndex, 1, getEmployeeViewObject(updatedEmployee));
    setEmployees(updatedEmployees);
  }

  const getCompany = (id) => {
    setShowLoader(true);
    getCompanyById(id)
      .then((result) => {
        setSelectedCompany(result);
      }).finally(() => {
      setShowLoader(false);
    });
  }

  const getPageHeader = () => {
    if (!selectedCompany ||
      ( selectedCompany && !selectedEmployee && userCompanies?.length === 1 && user.role !== Role.admin ) ||
      ( selectedEmployee && selectedCompany?.companyUsers?.length === 1 && userCompanies?.length === 1 && user.role !== Role.admin ))
      return <div>{t('monthly-report')}</div>;

    if (( selectedCompany && userCompanies?.length > 1 ) &&
      ( !selectedEmployee || ( selectedEmployee && selectedCompany?.companyUsers?.length === 1 ) ))
      return <div onClick={() => {
        setSelectedCompany(null);
        setSelectedEmployee(null);
      }}>
        <ArrowBackIcon/>
        {selectedCompany.company_name}
      </div>

    if (selectedEmployee && selectedCompany?.companyUsers?.length > 1)
      return <div onClick={() => setSelectedEmployee(null)}>
        <ArrowBackIcon/>
        {selectedEmployee.user.firstname}
      </div>
  }

  return <div className="monthly-report-page-wrapper">
    <header className="monthly-report-header">
      <div className="monthly-report-header-title">
        {getPageHeader()}
      </div>
      {( user?.role !== Role.admin || ( user?.role === Role.admin && !selectedCompany ) ) &&
      <div>
        <DateCarousel onMonthChange={setCurrentMonth}/>
      </div>
      }
    </header>

    {userCompanies?.length > 1 && !selectedCompany && <CompaniesList
      companies={userCompanies}
      currentMonth={currentMonth}
      onCompanyClick={getCompany}
      user={user}
    />}

    {selectedCompany && !selectedEmployee && <EmployeesList
      company={selectedCompany}
      exchangeRate={exchangeRate}
      selectedReport={selectedReport}
      user={user}
      employees={employees}
      onSubmitReport={() => getCompany(selectedCompany.id)}
    />}

    {selectedCompany && selectedEmployee && <EmployeeReport
      user={user}
      company={selectedCompany}
      exchangeRate={exchangeRate}
      selectedEmployee={selectedEmployee}
      setSelectedEmployee={setSelectedEmployee}
      employees={employees}
      selectedReport={selectedReport}
      setShowLoader={setShowLoader}
      getEmployeeViewObject={getEmployeeViewObject}
      onEmployeeUpdate={employeeUpdate}
      onSubmitReport={() => getCompany(selectedCompany.id)}
    />}
    {showLoader && <Loader/>}
  </div>
}

export default MonthlyReport;

