import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import AppLogo from '../../shared/icons/logo.svg';
import { ReactComponent as MonthlyReportIcon } from '../../shared/icons/monthly-report-icon.svg';
import { ReactComponent as EmployeesIcon } from '../../shared/icons/employees-icon.svg';
import { ReactComponent as ProfileIcon } from '../../shared/icons/profile-icon.svg';
import { ReactComponent as UploadIcon } from '../../shared/icons/upload-icon.svg';
import { ReactComponent as MissedFilesIcon } from '../../shared/icons/missed-files-icon.svg';
import { ReactComponent as SalaryIcon } from '../../shared/icons/salary-icon.svg';

import Text from '../../containers/Text';
import { GiHamburgerMenu } from 'react-icons/gi';
import {
  AiOutlineHome,
  AiOutlineFile,
} from 'react-icons/ai';

import './Menu.scss';
import { Link } from 'react-router-dom';
import useWindowDimensions from '../../hooks/windowResize';
import { useHistory } from 'react-router-dom';
import useOutsideClick from '../../hooks/outsideClick';
import { SessionStorageService } from '../../shared/services/session-storage';
import { Routes } from "../../api/routes";
import { Divider } from "@material-ui/core";

const Menu = ({
  menuClassName,
  handleDrawer,
  user,
  currentLng,
  changeLanguage,
  setLanguage,
  activePage,
  refreshContent
}) => {
  const { t } = useTranslation();
  const { screenWidth } = useWindowDimensions();

  const setNewLanguage = (lng) => {
    changeLanguage(lng);
    SessionStorageService.setAppLanguage(lng);
    setLanguage({ language: lng });
  };

  const history = useHistory();

  const menuRef = useRef(null);

  function redirectToHome() {
    history.push('/');
  }

  const handleMenu = () => {
    if (menuClassName === 'nav-bar') {
      handleDrawer();
    }
  };

  const clickOnMenuItem = (url) => {
    if (window.location.pathname === url) {
      refreshContent();
    } else
      handleDrawer();
  };

  useOutsideClick(menuRef, handleMenu);

  return (
    <div className="menu-container" ref={menuRef}>
      <div className={menuClassName}>
        {screenWidth > 600 && (
          <div className="nav-bar-header">
            <Text
              text={
                <img
                  src={AppLogo}
                  onClick={redirectToHome}
                  className="app-logo"
                  alt="app logo"
                />
              }
            />
            <GiHamburgerMenu
              size={35}
              className={`nav-bar-icon`}
              onClick={handleDrawer}
            />
          </div>
        )}
        <div className="buttons-container">
          <Link
            onClick={() => clickOnMenuItem('/')}
            className={activePage === '/' ? 'home-page active' : 'home-page'}
            to={'/'}
          >
            <AiOutlineHome className="menu-icon"/>
            <Text text={t('home-page')}/>
          </Link>

          {user.role === 'admin' && <>
            <Link
              onClick={() => clickOnMenuItem(Routes.accounts)}
              className={
                activePage === Routes.accounts
                  ? 'menu-item active'
                  : 'menu-item'
              }
              to={Routes.accounts}
            >
              <EmployeesIcon className="menu-icon"/>
              <Text text={t('accounts')}/>
            </Link>
            <Link
              onClick={() => clickOnMenuItem(Routes.salaries)}
              className={
                activePage === Routes.salaries
                  ? 'menu-item active'
                  : 'menu-item'
              }
              to={Routes.salaries}
            >
              <SalaryIcon className="menu-icon"/>
              <Text text={t('salaries')}/>
            </Link>
          </>}

          <Link
            onClick={() => clickOnMenuItem(Routes.monthlyReport)}
            className={
              activePage === Routes.monthlyReport
                ? 'menu-item active'
                : 'menu-item'
            }
            to={Routes.monthlyReport}
          >
            <MonthlyReportIcon className="menu-icon"/>
            <Text text={t('monthly-report')}/>
          </Link>

          {user.role === 'admin' ? (
            <>
              <Divider/>
              <Link
                onClick={() => clickOnMenuItem('/admin-panel')}
                className={
                  ( activePage === '/admin-panel'
                    ? 'menu-item active'
                    : 'menu-item' ) + ' top-margin-menu-item'
                }
                to={'/admin-panel'}
              >
                <AiOutlineFile className="menu-icon"/>
                <Text text={t('all-files')}/>
              </Link>

              <Link
                onClick={() => clickOnMenuItem('/missed')}
                className={
                  activePage === '/missed' ? 'menu-item active' : 'menu-item'
                }
                to={'/missed'}
              >
                <MissedFilesIcon className="menu-icon"/>
                <Text text={t('missed-files')}/>
              </Link>

              <Link
                onClick={() => clickOnMenuItem('/files-upload')}
                className={
                  activePage === '/files-upload'
                    ? 'menu-item active'
                    : 'menu-item'
                }
                to={'/files-upload'}
              >
                <UploadIcon className="menu-icon"/>
                <Text text={t('upload-files')}/>
              </Link>
            </>
          ) : (
            <Link
              onClick={() => clickOnMenuItem('/monthly-overview')}
              className={
                activePage === '/monthly-overview'
                  ? 'menu-item active'
                  : 'menu-item'
              }
              to={'/monthly-overview'}
            >
              <AiOutlineFile className="menu-icon"/>
              <Text text={t('monthly-overview')}/>
            </Link>
          )}

          <Divider/>

          <Link
            onClick={() => clickOnMenuItem('/user-page')}
            to={`/user/${user.id}`}
            className={
              ( activePage === '/user-page'
                ? 'menu-item active'
                : 'menu-item' ) + ' top-margin-menu-item'
            }
          >
            <ProfileIcon className="menu-icon"/>
            <Text text={t('profile')}/>
          </Link>
        </div>
        <div className="nav-bar-footer">

          <div className="lng-change-wrapper">
            <div className="lng-change-container">
              <button
                onClick={() => setNewLanguage('en')}
                className={`lng-button ${currentLng === 'en' ? 'active' : ''}`}
              >
                <Text text={t('eng-lang-button')}/>
              </button>
              <button
                onClick={() => setNewLanguage('heb')}
                className={`lng-button ${currentLng === 'heb' ? 'active' : ''}`}
              >
                <Text text={t('heb-lang-button')}/>
              </button>
            </div>
          </div>
        </div>
      </div>


    </div>
  );
};

export default Menu;
