import * as _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import MonthlyReportHeader from "../monthly-report-header";
import YeulsTable from "../../yeuls-table";
import MonthlyReportFooter from "../monthly-report-footer";
import { ReactComponent as ArrowBackIcon } from "../../../shared/icons/arrow-back-icon.svg";
import { ReactComponent as ArrowForwardIcon } from "../../../shared/icons/arrow-forward-icon.svg";
import {
  addWorkerReportEmployee,
  submitWorkerReportEmployee,
  updateWorkerReportEmployeeItem
} from "../../../api/requests/worker-report";
import RateItemOptions from "./rate-item-options";
import EditableLineInput from "../../editable-line-input";
import "./EmployeesReport.scss";
import { CompanyRole, Role } from "../../../shared/constants";
import ConfirmDialog from "../../confirm-dialog";
import YeulsButton from "../../yeuls-button";
import RateButton from "./rate-item-options/rate-button";
import { TextField } from "@material-ui/core";

const EmployeeReport = (props) => {
  const { t } = useTranslation();
  const {
    user,
    company,
    exchangeRate,
    selectedEmployee,
    setSelectedEmployee,
    employees,
    onEmployeeUpdate,
    selectedReport,
    onSubmitReport,
    setShowLoader
  } = props;
  const [nextEmployee, setNextEmployee] = useState(null);
  const [previousEmployee, setPreviousEmployee] = useState(null);
  const [selectedEmployeeReport, setSelectedEmployeeReport] = useState(null);
  const [reportItemUpdate, setReportItemUpdate] = useState(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const companyUser = useMemo(() => {
    if (!employees || !user)
      return;

    return _.find(employees, { user_id: user.id });

  }, [user, employees]);

  const reportSubmittedByManager = useMemo(() => {
    if (!selectedReport)
      return null;

    if (user.role === Role.admin)
      return true; //admin can't edit report

    if (companyUser.company_role === CompanyRole.manager)
      return !!selectedReport.submitted_by_manager_at;
    // eslint-disable-next-line
  }, [selectedReport]);

  const { companyTableColumns, employeeTableHeaders } = useMemo(() => {
    if (!selectedEmployee)
      return;

    if (selectedEmployee.is_daily_rate)
      return {
        companyTableColumns: "dateView.descriptionView.rateView.totalAmountView",
        employeeTableHeaders: [t("date"), t("note"), t("rate"), t("amount")]
      }

    if (!selectedEmployee.is_daily_rate)
      return {
        companyTableColumns: "dateView.descriptionView.typeView.hoursView.totalAmountView",
        employeeTableHeaders: [t("date"), t("note"), t("type"), t("hours"), t("amount")]
      }
    // eslint-disable-next-line
  }, [selectedEmployee]);

  useEffect(() => {
    if (!selectedEmployee || !selectedReport)
      return;

    const currentReport = selectedEmployee.reports.find((employeeReport) => employeeReport.report.month === selectedReport.month && employeeReport.report.year === selectedReport.year)

    if (!currentReport) {
      setShowLoader(true);
      addWorkerReportEmployee({
        user_company_id: selectedEmployee.id,
        worker_report_id: selectedReport.id
      })
        .then((value) => refreshSelectedEmployeeReport(value))
        .finally(() => setShowLoader(false));
      return;
    }

    setSelectedEmployeeReport({
      ...currentReport,
      reportData: _(currentReport.reportData).map(getReportItemViewObject, !!currentReport.submitted_by_employee_at).sortBy("day").value()
    });
    // eslint-disable-next-line
  }, [selectedEmployee, selectedReport]);

  useEffect(() => {
    if (!reportItemUpdate)
      return;

    if (!isCanEdit())
      return;

    const { day, rate, description, vacation, sick_day, hours } = reportItemUpdate;
    const currentReportItem = _.find(selectedEmployeeReport.reportData, { day });

    const updatedReportItem = { ...currentReportItem };

    if (vacation !== undefined) {
      updatedReportItem.vacation = vacation;
      updatedReportItem.sick_day = false;
      updatedReportItem.hours = 0;
      updatedReportItem.total_amount = 0;
    }

    if (sick_day !== undefined) {
      updatedReportItem.vacation = false;
      updatedReportItem.sick_day = sick_day;
      updatedReportItem.hours = 0;
      updatedReportItem.total_amount = 0;
    }

    if (description !== undefined) {
      updatedReportItem.description = description;
    }

    if (hours !== undefined) {
      if (!hours || hours < 0 || hours > 24) {
        updatedReportItem.hoursError = true;
        refreshSelectedEmployeeReportItem(updatedReportItem);

        return;
      } else {
        updatedReportItem.hours = hours;
        updatedReportItem.hoursError = false;
      }
    }

    if (!selectedEmployee.is_daily_rate) {
      if (updatedReportItem.sick_day || updatedReportItem.vacation)
        updatedReportItem.total_amount = 0;
      else
        updatedReportItem.total_amount = Math.floor(updatedReportItem.hours * selectedEmployee.hourly_rate * 100) / 100;
    } else if (rate !== undefined) {
      const dailyRate = selectedEmployee.daily_rate || company.companyTerms.default_daily_rate;
      updatedReportItem.rate = rate || 0;
      updatedReportItem.total_amount = Math.floor(dailyRate * updatedReportItem.rate) / 100
    }

    updateWorkerReportEmployeeItem(updatedReportItem)
      .catch(() => refreshSelectedEmployeeReportItem(currentReportItem));

    refreshSelectedEmployeeReportItem(updatedReportItem);
    // eslint-disable-next-line
  }, [reportItemUpdate]);

  useEffect(() => {
    if (!selectedEmployee) {
      setSelectedEmployeeReport(null);
      setNextEmployee(null);
      setPreviousEmployee(null);
      return;
    }

    const selectedEmployeeIndex = _.findIndex(employees, (employee) => employee.id === selectedEmployee.id);

    if (selectedEmployeeIndex === -1) {
      setSelectedEmployeeReport(null);
      setNextEmployee(null);
      setPreviousEmployee(null);
      return;
    }

    if (employees.length === 1) {
      setNextEmployee(null);
      setPreviousEmployee(null);
    }

    if (selectedEmployeeIndex === 0) {
      setNextEmployee(employees[1]);
      setPreviousEmployee(employees[employees.length - 1]);
      return;
    }

    if (selectedEmployeeIndex === employees.length - 1) {
      setNextEmployee(employees[0]);
      setPreviousEmployee(employees[employees.length - 2]);
      return;
    }

    setNextEmployee(employees[selectedEmployeeIndex + 1]);
    setPreviousEmployee(employees[selectedEmployeeIndex - 1]);
  }, [selectedEmployee, employees]);

  const getReportItemViewObject = (reportItem, submittedByEmployee = selectedEmployeeReport.submitted_by_employee_at) => {
    const dateOptions = { year: 'numeric', month: 'numeric', day: 'numeric' };
    const date = new Date(selectedReport.year, selectedReport.month - 1, reportItem.day);
    const totalAmountNis = Math.floor(reportItem.total_amount * ( selectedEmployeeReport?.report?.submitted_by_manager_at ? selectedEmployeeReport?.report.exchange_rate : exchangeRate ) * 100) / 100;

    const reportItemView = {
      ...reportItem,
      dateView: date.toLocaleDateString("en-GB", dateOptions),
      totalAmountView: reportItem.total_amount ? `$${reportItem.total_amount}/₪${totalAmountNis}` : 0,
      descriptionView: reportSubmittedByManager ?
        reportItem.description :
        <EditableLineInput
          value={reportItem.description}
          onSave={(description) => onReportChange({
            description,
            day: reportItem.day
          })}>{reportItem.description}</EditableLineInput>
    };

    if (!selectedEmployee.is_daily_rate) {
      reportItemView.typeView = <div className="employees-report-hourly-wrapper">
        <RateButton onClick={() => onReportChange({ sick_day: !reportItem.sick_day, day: reportItem.day })}
                    isActive={reportItem.sick_day}>
          {t('sick-day')}
        </RateButton>
        <RateButton onClick={() => onReportChange({ vacation: !reportItem.vacation, day: reportItem.day })}
                    isActive={reportItem.vacation}>
          {t('vacation')}
        </RateButton>
      </div>
      reportItemView.hoursView = <TextField
        variant="outlined"
        className="employees-report-hourly-input"
        type="number"
        defaultValue={reportItem.hours}
        disabled={reportItem.vacation || reportItem.sick_day}
        error={reportItem.hoursError}
        onBlur={(e) => {
          console.log(e.target.value);
          onReportChange({ day: reportItem.day, hours: e.target.value })
        }}
        // onChange={(event) => setFormData({ ...formData, [field]: { value: event.target.value, error: false } })}
      />
    } else {
      reportItemView.rateView = <RateItemOptions
        day={reportItem.day}
        onRateChange={(day, rate) => {
          if (( companyUser.company_role === CompanyRole.manager && reportSubmittedByManager ) ||
            ( companyUser.company_role === CompanyRole.employee && ( reportSubmittedByManager || submittedByEmployee ) ))
            return;

          onReportChange({ day, rate: reportItem.rate !== rate ? rate : null });
        }}
        rate={reportItem.rate}/>;
    }

    return reportItemView
  }

  const refreshSelectedEmployeeReport = (newReport) => {
    let updatedEmployee = { ...selectedEmployee };
    const index = _.findIndex(selectedEmployee.reports, (report) => report.id === newReport.id);

    if (index !== -1) {
      updatedEmployee.reports.splice(index, 1, newReport);
    } else {
      updatedEmployee.reports = [...selectedEmployee.reports, newReport];
    }

    setSelectedEmployee(updatedEmployee);
    onEmployeeUpdate(updatedEmployee)
  }

  const refreshSelectedEmployeeReportItem = (newReportItem) => {
    const currentReportItemIndex = _.findIndex(selectedEmployeeReport.reportData, { day: newReportItem.day });
    const updatedReportData = [...selectedEmployeeReport.reportData];
    updatedReportData.splice(currentReportItemIndex, 1, getReportItemViewObject(newReportItem));

    refreshSelectedEmployeeReport({ ...selectedEmployeeReport, reportData: updatedReportData });
  }

  const isCanEdit = () => {
    if (user.role === Role.admin)
      return false;

    if (companyUser.company_role === CompanyRole.employee) {
      return !selectedEmployeeReport.submitted_by_employee_at;

    }

    if (companyUser.company_role === CompanyRole.manager) {
      return !selectedEmployeeReport.report.submitted_by_manager_at;
    }
  }

  const onReportChange = (changes) => {
    setReportItemUpdate(changes);
  }

  const submitReport = async () => {
    await submitWorkerReportEmployee(selectedEmployeeReport.id);
    onSubmitReport();
    setOpenConfirmDialog(false);
  }

  return <div className="employees-report-wrapper">
    <MonthlyReportHeader
      hourlyRate={!selectedEmployee?.is_daily_rate ? selectedEmployee.hourly_rate : null}
      dailyRate={selectedEmployee?.daily_rate || company?.companyTerms.default_daily_rate}
      exchangeRate={exchangeRate}/>
    <div className={`employees-report-table-wrapper ${!selectedEmployee?.is_daily_rate ? "hourly-rate-table" : ""}`}>
      {selectedEmployeeReport && <YeulsTable
        data={selectedEmployeeReport?.reportData}
        headers={employeeTableHeaders}
        columns={companyTableColumns}
        pagination={false}/>}
    </div>
    <MonthlyReportFooter
      employee={selectedEmployee}/>
    {employees?.length > 1 && <div className="employees-report-switcher-wrapper">
      {previousEmployee && <div onClick={() => setSelectedEmployee(previousEmployee)}>
        <ArrowBackIcon/>
        {previousEmployee.user.firstname}
      </div>}

      {nextEmployee && <div onClick={() => setSelectedEmployee(nextEmployee)}>
        {nextEmployee.user.firstname}
        <ArrowForwardIcon/>
      </div>}
    </div>}
    {employees?.length === 1 &&
    <div className="employees-report-button-wrapper">
      <YeulsButton
        onClick={() => setOpenConfirmDialog(true)}
        disabled={!!selectedEmployeeReport?.submitted_by_employee_at}
      >{t('send-report')}</YeulsButton>
    </div>}

    <ConfirmDialog
      open={openConfirmDialog}
      onClose={() => setOpenConfirmDialog(false)}
      title={t('validate-and-approve')}
      onSubmit={submitReport}
    >
      <div>
        <p>
          Make sure you check each employee and complete all fields. After clicking the "Confirm" button, you will not
          be able to modify the reports.
        </p>
        <p>
          If you made a mistake or forget to fill some information please contacts with administrator to resolve this
          issue.
        </p>
      </div>
    </ConfirmDialog>
  </div>
}
export default EmployeeReport;