import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';

import { useTranslation } from 'react-i18next';

import { AuthService } from '../../services/auth';
import * as _ from 'lodash';

import Spinner from '../spinner';
import { AiFillEyeInvisible, AiFillEye } from 'react-icons/ai';
import { useHistory } from 'react-router-dom';

import Text from '../../containers/Text';
import './UserPage.scss';
import { constants } from '../../api/constants';

export default function UserPage({ user, setUserInfo, setMonthlyOverview }) {
  const { t } = useTranslation();
  const { id } = useParams();
  const history = useHistory();

  const [userData, setUserData] = useState({});

  const [isLoading, setIsLoading] = useState(true);
  const [isPasswordShowing, setPasswordShowing] = useState(false);

  useEffect(() => {
    async function getUserData() {
      try {
        if (+user.id !== +id) {
          history.push('/');
          setIsLoading(false);
          return;
        }

        const userToken = await AuthService.getToken();
        const response = await fetch(`${constants.baseURL}/user/${id}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `bearer ${userToken}`,
          },
        });
        const parsedResult = await response.json();
        const data = {
          id: id,
          firstname: parsedResult.result.firstname,
          email: parsedResult.result.email,
          phone: parsedResult.result.phone,
          password: '',
          job_title: parsedResult.result.job_title,
        };

        setUserData(data);
      } catch (err) {
      } finally {
        setIsLoading(false);
      }
    }

    getUserData();
  }, [user, id, history]);

  const updateUserData = (value, field) => {
    setUserData({ ...userData, [field]: value });
  };

  const updateUser = async () => {
    setIsLoading(true);

    if (_.isEmpty(userData.email)) {
      setIsLoading(false);
      return;
    }

    const accessToken = localStorage.getItem('access_token');
    await fetch(`${constants.baseURL}/user/update`, {
      method: 'PUT',
      body: JSON.stringify(userData),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `bearer ${accessToken}`,
      },
    });

    const updatedTokenResponse = await fetch(
      `${constants.baseURL}/auth/refresh`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `bearer ${accessToken}`,
        },
      }
    );

    const parsedUpdatedTokenResult = await updatedTokenResponse.json();
    AuthService.setToken(parsedUpdatedTokenResult.result);
    const decodedToken = AuthService.getTokenData();
    setUserInfo({ ...decodedToken });
    setIsLoading(false);
  };

  const signOut = () => {
    AuthService.deleteToken();
    setUserInfo({ id: null });
    setMonthlyOverview(null);
    history.push('/');
  };

  return (
    <div className="user-page-container">
      <div className="user-content-row">
        <p className="user-page-title">
          <Text text={t('profile-info')} />
        </p>

        {isLoading && (
          <div className="user-page-container">
            <Spinner />
          </div>
        )}

        {!isLoading && (
          <div className="user-info">
            <div className="user-page-content">
              <div className="user-data">
                <Text text={t('name')} />
                <input
                  value={userData.firstname}
                  type="text"
                  onChange={(e) => updateUserData(e.target.value, 'firstname')}
                />
              </div>

              <div className="user-data">
                <Text text={t('e-mail')} />
                <input
                  value={userData.email}
                  type="text"
                  onChange={(e) => updateUserData(e.target.value, 'email')}
                />
              </div>

              <div className="user-data">
                <Text text={t('phone')} />
                <input
                  value={userData.phone}
                  type="text"
                  onChange={(e) => updateUserData(e.target.value, 'phone')}
                />
              </div>

              <div className="user-data">
                <Text text={t('job')} />
                <input
                  value={userData.job_title}
                  type="text"
                  onChange={(e) => updateUserData(e.target.value, 'job_title')}
                />
              </div>
            </div>
            <div className="user-password">
              <div className="user-data">
                <Text text={t('password')} />
                <div className="input-password">
                  <input
                    value={userData.password}
                    type={isPasswordShowing ? 'text' : 'password'}
                    onChange={(e) => updateUserData(e.target.value, 'password')}
                  />
                  <div onClick={() => setPasswordShowing(!isPasswordShowing)}>
                    {isPasswordShowing ? (
                      <AiFillEye className="eye" />
                    ) : (
                      <AiFillEyeInvisible className="eye" />
                    )}
                  </div>
                </div>
              </div>

              <div className="user-data button">
                <button onClick={updateUser} className="update-details-button">
                  <Text text={t('update-details')} />
                </button>
              </div>
            </div>
          </div>
        )}

        {!isLoading && (
          <div className="sign-out-button-container">
            <button onClick={signOut} className="sign-out">
              <Text text={t('sign-out')} />
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
