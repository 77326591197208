import React from 'react';

import * as moment from 'moment';
import Text from '../../containers/Text';
import './MonthlyOverview.scss';

const Year = (props) => {
  const { yeulsMonthlyOverview, id } = props;

  const getYearFromDate = (date) => {
    if (date.length === 10) {
      return moment(date, ['DD-MM-YYYY']).format('YYYY');
    } else {
      return moment(date).format('YYYY');
    }
  };

  const currentYear = getYearFromDate(
    yeulsMonthlyOverview[id].documents[0].created_on
  );

  if (id === 0) {
    return <Text text={<div className="current-year">{currentYear}</div>} />;
  }

  const previousYear = getYearFromDate(
    yeulsMonthlyOverview[id - 1].documents[0].created_on
  );

  if (currentYear !== previousYear) {
    return <Text text={<div className="current-year">{currentYear}</div>} />;
  }

  return <></>;
};

export default Year;
