import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import YeulsTabs from "../../../yeuls-tabs";

import "./AgentDocuments.scss";
import InvoicesList from "./invoices-list";
import ReceiptsList from "./receipts-list";
import ExpensesList from "./expenses-list";
import SalariesList from "./salaries-list";
import AccountsSearchField from "../accounts-search-field";

const tabIds = {
  invoices: 0,
  receipts: 1,
  expenses: 2,
  salaries: 3,
}

const AgentDocuments = (props) => {
  const {
    invoices,
    receipts,
    expenses,
    salaries,
    clients,
    suppliers,
    invoiceChanges = {},
    setInvoiceChanges = () => {},
    expenseChanges = {},
    setExpenseChanges = () => {}
  } = props;
  const { t, i18n } = useTranslation();
  const [currentTabId, setCurrentTabId] = useState(tabIds.invoices);
  const [filterValue, setFilterValue] = useState("");
  const [updateTable, setUpdateTable] = useState(true);

  const tabsConfig = useMemo(() => {
      const config = [];

      if (invoices) {
        config.push({ id: tabIds.invoices, label: t('invoices'), itemsCount: invoices?.length });
      }

      if (receipts) {
        config.push({ id: tabIds.receipts, label: t('receipts'), itemsCount: receipts?.length });
      }

      if (expenses) {
        config.push({ id: tabIds.expenses, label: t('expenses'), itemsCount: expenses?.length });
      }

      if (salaries) {
        config.push({ id: tabIds.salaries, label: t('salaries'), itemsCount: salaries?.length });
      }

      if (config.length) {
        config[0].defaultSelectedTab = true;
      }

      return config;
    },
    // eslint-disable-next-line
    [i18n.language, invoices, receipts, expenses, salaries]);

  return <div className="agent-documents-wrapper">
    <div className="agent-documents-tabs-wrapper">
      <YeulsTabs
        config={tabsConfig}
        onChange={setCurrentTabId}/>
    </div>
    <div className="agent-documents-content">
      <AccountsSearchField filterValue={filterValue}
                           setFilterValue={(value) => {
                             setUpdateTable(false);
                             setTimeout(() => setUpdateTable(true));
                             setFilterValue(value);
                           }}
                           showSearchMessage={false} filter={filterValue}/>
      {currentTabId === tabIds.invoices && updateTable &&
      <InvoicesList invoices={invoices} clients={clients} invoiceChanges={invoiceChanges}
                    setInvoiceChanges={setInvoiceChanges} filterValue={filterValue}/>}
      {currentTabId === tabIds.receipts && updateTable &&
      <ReceiptsList receipts={receipts} invoices={invoices} clients={clients} filterValue={filterValue}/>}
      {currentTabId === tabIds.expenses && updateTable &&
      <ExpensesList expenses={expenses} suppliers={suppliers} expenseChanges={expenseChanges}
                    setExpenseChanges={setExpenseChanges} filterValue={filterValue}/>}
      {currentTabId === tabIds.salaries && updateTable &&
      <SalariesList salaries={salaries} filterValue={filterValue}/>}
    </div>
  </div>
}

export default AgentDocuments;