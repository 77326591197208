import React, { useEffect, useState } from "react";

import "./UserCompaniesItemDetails.scss";
import { useTranslation } from "react-i18next";
import { Checkbox, Divider, Grid } from "@material-ui/core";
import { Mode } from "../../../../../../shared/constants";
import DetailComponent from "../../../../common-components/details-component";
import UserCompaniesFormItem from "../../../../common-components/forms/user-companies-form";
import { getUserNameView } from "../../../../common-components/utils";

const defaultUserCompanyFormData = {
  daily_rate: { value: null, error: false },
  hourly_rate: { value: null, error: false },
  company_role: { value: null, error: false },
  is_daily_rate: { value: true, error: false },
}

const CompanyUserItemDetails = (props) => {
  const { user, onChange, companyUser, mode } = props;
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(!!companyUser)
  const [companyUserFormData, setCompanyUserFormData] = useState(
    !companyUser ?
      defaultUserCompanyFormData :
      {
        daily_rate: { value: companyUser.daily_rate, error: false },
        hourly_rate: { value: companyUser.hourly_rate, error: false },
        company_role: { value: companyUser.company_role, error: false },
        is_daily_rate: { value: companyUser.is_daily_rate, error: false },
      });

  useEffect(() => {
    onChange(!expanded ? null : {
      ...(companyUser || {}),
      daily_rate: companyUserFormData.daily_rate.value,
      hourly_rate: companyUserFormData.hourly_rate.value,
      company_role: companyUserFormData.company_role.value,
      is_daily_rate: companyUserFormData.is_daily_rate.value,
      user_id: user.id
    });
    // eslint-disable-next-line
  }, [companyUserFormData, expanded]);

  if (mode === Mode.view && !companyUser)
    return null;

  return <div className="company-users-details-item">
    <div className="company-users-details-item-header">
      <Grid container>
        {mode === Mode.edit &&
        <Grid xs={1} item className="vertically-center">
          <Checkbox checked={expanded} onChange={(e) => setExpanded(e.target.checked)}/>
        </Grid>}
        <Grid xs={11} item className="vertically-center company-users-details-item-title">
          {getUserNameView(user, t)}
        </Grid>
      </Grid>
    </div>
    {expanded && <Divider className="company-users-details-divider"/>}
    {expanded && <div className="company-users-details-item-content">
      {mode === Mode.view && <Grid container spacing={3}>
        <Grid item xs={6}><DetailComponent label={t("daily-rate")} value={companyUser.daily_rate}/></Grid>
        <Grid item xs={6}><DetailComponent label={t("hourly-rate")} value={companyUser.hourly_rate}/></Grid>
        <Grid item xs={6}><DetailComponent label={t("role")} value={t(companyUser.company_role)}/></Grid>
        <Grid item xs={6}><DetailComponent label={t("is-use-daily-rate")}
                                           value={companyUser.is_daily_rate ? t("yes") : t("no")}/></Grid>
      </Grid>}
      {mode === Mode.edit && <UserCompaniesFormItem
        companyUserFormData={companyUserFormData}
        setCompanyUserFormData={setCompanyUserFormData}/>}
    </div>}
  </div>

}

export default CompanyUserItemDetails;