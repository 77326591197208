const appEnvironment = process.env.REACT_APP_ENVIRONMENT;
let baseURL;

switch (appEnvironment?.trim()){
  case "local":
    baseURL = process.env.REACT_APP_LOCAL_API_BASE_URL;
    break;
  case "dev":
    baseURL = process.env.REACT_APP_DEV_API_BASE_URL;
    break;
  case "prod":
    baseURL = process.env.REACT_APP_PROD_API_BASE_URL;
    break;
  default:
    baseURL = process.env.REACT_APP_DEV_API_BASE_URL;
}

export const constants = {
  baseURL
  // baseURL: 'https://portal.yeuls.co.il/api/v1', // prop
  // baseURL: 'https://yeuls-portal-dev.herokuapp.com/api/v1', // dev
  // baseURL: 'http://localhost:4322/api/v1', // local server
};
