import * as _ from "lodash";
import React, { useMemo } from 'react';
import YeulsTable from '../../yeuls-table';
import { useTranslation } from "react-i18next";
import { Tooltip } from "@material-ui/core";

import "./AgentsList.scss";

const AgentsList = (props) => {
  const { t, i18n } = useTranslation();
  const { users, setSelectedUser } = props;

  const mappedUsers = useMemo(() => _.map(users, (user) => {
    const statusView = user.active ?
      <div className="status active">{t("active")}</div> :
      <div className="status dismissed">{t("dismissed")}</div>;
    const emailView = <Tooltip title={user.email}>
      <div>{user.email}</div>
    </Tooltip>;
    const nameView = <div className="clickable-block" onClick={() => setSelectedUser(user)}>{user.firstname}</div>;
    const identityNumber = user.identity_number ? "000000000" + user.identity_number : "";
    const identityNumberView = identityNumber.substring(identityNumber.length - 9);

    return {
      ...user,
      statusView,
      emailView,
      identityNumberView,
      nameView
    }
    // eslint-disable-next-line
  }), [users]);
  const headers = useMemo(() => [t("name"), t("id"), t("agent-id"), t("email"), t("status")], [i18n.language]);

  return <div className={"agents-list-wrapper"}>
    <YeulsTable
      data={mappedUsers}
      headers={headers}
      columns="nameView.identityNumberView.santec_account_id.emailView.statusView"
      totalCount={mappedUsers.length}
      perPageItemCount={12}
      partialPageCount={3}
      nextPageText=">"
      prePageText="<"
      className={"employees-table-container"}
    />
  </div>
}

export default AgentsList;