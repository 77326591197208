import { Routes } from "../api/routes";

export class ActivePageService {
  static getActivePageUrl(url) {
    if (url.indexOf('/user/') !== -1)
      return '/user-page';

    switch (url) {
      case '/':
        return '/';

      case '/monthly-overview':
        return '/monthly-overview';

      case '/admin-panel':
        return '/admin-panel';

      case '/forgot-password':
        return '/forgot-password';

      case '/reset-password':
        return '/reset-password';

      case '/files-upload':
        return '/files-upload';

      case '/missed':
        return '/missed';

      case Routes.accounts:
        return Routes.accounts;

      case Routes.monthlyReport:
        return Routes.monthlyReport;

      case Routes.salaries:
        return Routes.salaries;

      default:
        return null;
    }
  }
}
