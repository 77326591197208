import * as _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";

import "./ClientDetails.scss";
import { useTranslation } from "react-i18next";
import { Mode } from "../../../../shared/constants";
import YeulsTabs from "../../../yeuls-tabs";
import YeulsButton from "../../../yeuls-button";
import GeneralClientDetails from "./general-client-details";
import BankClientDetails from "./bank-client-details";
import ClientAgentsDetails from "./client-agents-details";

const tabIds = {
  general: 0,
  bank: 1,
  agents: 2
}

const ClientDetails = (props) => {
  const { client, onUpdateClient, users, companies } = props;
  const { t, i18n } = useTranslation();
  const [currentTabId, setCurrentTabId] = useState(tabIds.general);
  const [mode, setMode] = useState(Mode.edit);
  const [clientFormData, setClientFormData] = useState(getClientFormObject(client));
  const [clientUserAgentsChanges, setClientUserAgentsChanges] = useState({});
  const [clientCompanyAgentsChanges, setClientCompanyAgentsChanges] = useState({});

  const tabsConfig = useMemo(() => [
      { id: tabIds.general, label: t('general'), defaultSelectedTab: true },
      { id: tabIds.bank, label: t('bank') },
      { id: tabIds.agents, label: t('agents'), itemsCount: client.clientAgents?.length }
    ],
    // eslint-disable-next-line
    [i18n.language, client]);

  useEffect(() => {
    if (mode === Mode.edit)
      setClientFormData(getClientFormObject(client));
    // eslint-disable-next-line
  }, [mode])

  const onSaveClick = () => {
    const clientUpdate = {
      ...client,
      client_name: clientFormData.client_name.value,
      country: clientFormData.country.value,
      city: clientFormData.city.value,
      address: clientFormData.address.value,
      zip: clientFormData.zip.value,
      phone: clientFormData.phone.value,
      email: clientFormData.email.value,
      business_number: clientFormData.business_number.value,
      withholding_tax: +clientFormData.withholding_tax.value,
      withholding_tax_expiration: clientFormData.withholding_tax_expiration.value,
      general_ledger: clientFormData.general_ledger.value,
      created_by: clientFormData.created_by.value,
      included_vat: clientFormData.included_vat.value,
      currency: clientFormData.currency.value,

      bank_name: clientFormData.bank_name.value,
      bank_number: clientFormData.bank_number.value,
      bank_branch_number: clientFormData.bank_branch_number.value,
      bank_account_number: clientFormData.bank_account_number.value,
    }

    const clientAgents = [..._.values(clientUserAgentsChanges).filter(Boolean), ..._.values(clientCompanyAgentsChanges).filter(Boolean)]

    onUpdateClient(clientUpdate, clientAgents)
      .then(() => {
        setClientUserAgentsChanges({});
        setClientCompanyAgentsChanges({});
      })
      // .then(() => {
      //   setMode(Mode.view);
      // })
      .catch((e) => { });
  }

  const clientAgentChangeHandler = ({ userId, companyId }, clientAgent) => {
    if (userId)
      setClientUserAgentsChanges({ ...clientUserAgentsChanges, [userId]: clientAgent });

    if (companyId)
      setClientCompanyAgentsChanges({ ...clientCompanyAgentsChanges, [companyId]: clientAgent });
  }
  const onCancelClick = () => {
    // setMode(Mode.view);
    setClientUserAgentsChanges({});
    setClientCompanyAgentsChanges({});
    setClientFormData(getClientFormObject(client));
  }

  return <div className="client-details-wrapper">
    <div className="client-details-tabs-wrapper">
      <YeulsTabs
        config={tabsConfig}
        onChange={setCurrentTabId}/>
      {/*{mode === Mode.view && <YeulsButton onClick={() => setMode(Mode.edit)}>{t("edit")}</YeulsButton>}*/}
      {mode === Mode.edit && <div className="client-details-button-container">
        <YeulsButton variant="negative" onClick={() => onCancelClick()}>{t("reset")}</YeulsButton>
        <YeulsButton onClick={onSaveClick}>{t("save")}</YeulsButton>
      </div>}
    </div>
    <div className="client-details-content">
      {currentTabId === tabIds.general &&
      <GeneralClientDetails mode={mode} client={client} clientFormData={clientFormData}
                            setClientFormData={setClientFormData}/>}
      {currentTabId === tabIds.bank &&
      <BankClientDetails mode={mode} client={client} clientFormData={clientFormData}
                         setClientFormData={setClientFormData}/>}
      {currentTabId === tabIds.agents &&
      <ClientAgentsDetails
        mode={mode} client={client} users={users} companies={companies}
        onClientAgentChange={clientAgentChangeHandler}
        clientUserAgentsChanges={clientUserAgentsChanges}
        clientCompanyAgentsChanges={clientCompanyAgentsChanges}/>}
    </div>
  </div>
}

function getClientFormObject(client) {
  return {
    client_name: { value: client.client_name, error: false },
    country: { value: client.country, error: false },
    city: { value: client.city, error: false },
    address: { value: client.address, error: false },
    zip: { value: client.zip, error: false },
    phone: { value: client.phone, error: false },
    email: { value: client.email, error: false },
    business_number: { value: client.business_number, error: false },
    withholding_tax: { value: client.withholding_tax, error: false },
    withholding_tax_expiration: { value: client.withholding_tax_expiration, error: false },
    general_ledger: { value: client.general_ledger, error: false },
    created_by: { value: client.created_by, error: false },
    included_vat: { value: client.included_vat, error: false },
    currency: { value: client.currency, error: false },

    bank_name: { value: client.bank_name, error: false },
    bank_number: { value: client.bank_number, error: false },
    bank_branch_number: { value: client.bank_branch_number, error: false },
    bank_account_number: { value: client.bank_account_number, error: false },
  }
}

export default ClientDetails