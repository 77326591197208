import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';

import Text from '../../../containers/Text';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { IoIosAttach } from 'react-icons/io';
import { AiOutlineDelete } from 'react-icons/ai';

import useStyles from '../filesUpload.styles';

function TabPanel(props) {
  const {
    children,
    value,
    index,
    format,
    validator,
    uploadFiles,
    ...other
  } = props;
  const classes = useStyles();
  const { t, i18n } = useTranslation();


  const [filesCollection, setFilesCollection] = useState([]);
  const [uploadResults, setUploadResults] = useState([]);

  const onDrop = useCallback((acceptedFiles) => {
    setFilesCollection(acceptedFiles);
    setUploadResults([]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    noClick: false,
    noKeyboard: true,
    multiple: true,
    onDrop,
    accept: 'application/pdf, image/gif',
  });

  const onFilesUpload = async () => {
    const validatedFiles = validator(filesCollection);
    const someInvalidFiles = validatedFiles.some((f) => f?.invalid);

    if (someInvalidFiles) {
      setFilesCollection(validatedFiles);
      return;
    }

    const formData = new FormData();
    for (const key of Object.keys(filesCollection)) {
      formData.append('filesCollection', filesCollection[key]);
    }

    try {
      const response = await uploadFiles(formData);

      setUploadResults(response.data.result);
      setFilesCollection([]);
    } catch (error) {
      console.error('err:', error);
    }
  };

  const onFileRemove = (rId) => {
    const updatedFiles = filesCollection.filter((f, id) => id !== rId);
    setFilesCollection(updatedFiles);
  };

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      format=""
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div
            {...getRootProps({
              className: isDragActive ? classes.onDrag : classes.dropzone,
            })}
          >
            <input {...getInputProps()} />
            <p>
              <Text text={t('drag-n-drop')}/>
            </p>
            <p>
              <Text text={t('drag-n-drop-format')}/>
            </p>
            <p>
              <Text text={format}/>
            </p>
          </div>
        </Box>
      )}

      {filesCollection.length > 0 && (
        <div className={classes.fileUploadButtonsContainer}>
          <Button
            variant="contained"
            color="primary"
            onClick={onFilesUpload}
            className={classes.button}
          >
            <Text text={t('upload-files')}/>
          </Button>

          <Button
            variant="contained"
            color="primary"
            onClick={()=>setFilesCollection([])}
            className={classes.button}
          >
            <Text text={t('cancel')}/>
          </Button>
        </div>
      )}

      {filesCollection.map((f, id) => (
        <div
          key={f.name}
          className={f?.invalid ? classes.fileError : classes.file}
        >
          <div className={i18n.language !== 'heb'?  classes.fileName: classes.fileNameRevert}>
            <IoIosAttach/>
            {f.name}
          </div>
          <Button
            variant="text"
            color="primary"
            onClick={() => {
              onFileRemove(id);
            }}
            className={classes.removeButton}
            startIcon={<AiOutlineDelete/>}
          >
            <Text text={t('delete')}/>
          </Button>
        </div>
      ))}

      {uploadResults.length > 0 && (
        <div>
          {uploadResults.map((f, id) => (
            <p className={classes.report} key={id}>
              <Text text={t(f.message)}/>
            </p>
          ))}
        </div>
      )}
    </div>
  );
}

export default TabPanel;
