import * as _ from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CSVDownload } from "react-csv";

import YeulsTable from "../../yeuls-table";
import YeulsButton from "../../yeuls-button";
import MonthlyReportHeader from "../monthly-report-header";
import MonthlyReportFooter from "../monthly-report-footer";
import ConfirmDialog from "../../confirm-dialog";
import "./EmployeesList.scss";
import { submitWorkerReport } from "../../../api/requests/worker-report";
import { Role } from "../../../shared/constants";
import InvoiceConfig from "./invoice-config";

const EmployeesList = (props) => {
  const {
    company,
    exchangeRate,
    selectedReport,
    user,
    onSubmitReport,
    employees
  } = props;
  const { t } = useTranslation();
  const [totalData, setTotalData] = useState({ totalCostView: 0, totalFeeView: 0, totalView: 0 });
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [sendEmail, setSendEmail] = useState(true);
  const [csvData, setCsvData] = useState(null);
  const companyTableHeaders = [t("name"), t("agent-id"), t("days"), t("cost"), t("fee"), t("total")];
  const companyTableColumns = "firstnameView.identity_number.days.totalCostView.totalFeeView.totalView";
  const csvHeaders = [
    { label: t('firstname'), key: "firstname" },
    { label: t('agent-id'), key: "agentId" },
    { label: t('days'), key: "days" },
    { label: t('total-cost'), key: "totalCost" },
    { label: t('total-fee'), key: "totalFee" },
    { label: t('total'), key: "total" },
  ];

  useEffect(() => {
    const totalCost = Math.floor(_.sumBy(employees, 'totalCost') * 100) / 100;
    const totalCostNis = Math.floor(_.sumBy(employees, 'totalCostNis') * 100) / 100;
    const totalFee = Math.floor(_.sumBy(employees, 'totalFee') * 100) / 100;
    const totalFeeNis = Math.floor(_.sumBy(employees, 'totalFeeNis') * 100) / 100;
    const total = Math.floor(_.sumBy(employees, 'total') * 100) / 100;
    const totalNis = Math.floor(_.sumBy(employees, 'totalNis') * 100) / 100;

    setTotalData({
      totalCostView: totalCost ? `$${totalCost}/₪${totalCostNis}` : 0,
      totalFeeView: totalFee ? `$${totalFee}/₪${totalFeeNis}` : 0,
      totalView: total ? `$${total}/₪${totalNis}` : 0
    });
  }, [employees]);

  useEffect(() => {
    if (!csvData)
      return;

    setCsvData(null);
  }, [csvData]);

  const submitReport = async () => {
    const currentEmployeeUser = _.find(employees, { user_id: user.id, company_id: company.id });

    await submitWorkerReport(selectedReport.id, currentEmployeeUser.id, exchangeRate);
    onSubmitReport();
    setOpenConfirmDialog(false);
  }

  const generateCSV = () => {
    const data = employees.map((employee) => {
      return {
        firstname: employee.user.firstname,
        agentId: employee.user.santec_account_id,
        days: employee.days,
        totalCost: employee.totalCostView,
        totalFee: employee.totalFeeView,
        total: employee.totalView,
      }
    });
    data.push({ firstname: t('total-cost-with-colon'), total: totalData.totalCostView });
    data.push({ firstname: t('total-fee'), total: totalData.totalFeeView });
    data.push({ firstname: t('total'), total: totalData.totalView });

    setCsvData(data);
  }

  return <div className="employees-list-wrapper">
    {user?.role !== Role.admin &&
    <MonthlyReportHeader dailyRate={company?.companyTerms.default_daily_rate} exchangeRate={exchangeRate}/>}
    {user?.role === Role.admin && <InvoiceConfig dailyRate={company?.companyTerms.default_daily_rate} exchangeRate={exchangeRate}/>}
    <div className="employees-list-table-wrapper">
      <YeulsTable
        data={employees}
        headers={companyTableHeaders}
        columns={companyTableColumns}
        pagination={false}/>
    </div>
    <MonthlyReportFooter employee={totalData}/>
    <div className="employees-list-button-wrapper">
      {user?.role !== Role.admin &&
      <YeulsButton onClick={() => setOpenConfirmDialog(true)}
                   disabled={selectedReport?.submitted_by_manager_at}>{t('send-report')}</YeulsButton>}
      {user?.role === Role.admin && <div className="employees-list-admin-button-wrapper">
        <div className="employees-list-admin-send-email-wrapper">
          <YeulsButton onClick={generateCSV}>{t('create-csv')}</YeulsButton>
          <div className="send-on-email-wrapper">
            <div>{t('send-on-email')}</div>
            <div className="employees-list-admin-send-email-options">
              <div className={sendEmail ? 'active' : ''} onClick={() => setSendEmail(true)}>{t('yes')}</div>
              /
              <div className={!sendEmail ? 'active' : ''} onClick={() => setSendEmail(false)}>{t('no')}</div>
            </div>
          </div>
        </div>
        <YeulsButton disabled={selectedReport?.invoice_id}>{t('create-invoice')}</YeulsButton>
      </div>}
    </div>
    {csvData && <CSVDownload data={csvData} target="_blank" headers={csvHeaders}/>}
    <ConfirmDialog
      open={openConfirmDialog}
      onClose={() => setOpenConfirmDialog(false)}
      title={t('validate-and-approve')}
      onSubmit={submitReport}
    >
      <div>
        <p>
          Make sure you check each employee and complete all fields. After clicking the "Confirm" button, you will not
          be able to modify the reports.
        </p>
        <p>
          If you made a mistake or forget to fill some information please contacts with administrator to resolve this
          issue.
        </p>
      </div>
    </ConfirmDialog>
  </div>
}

export default EmployeesList;