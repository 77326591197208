import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ( {
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'block',
    width: 'fit-content',
    height: '100%',
    margin: 'auto',

    '& + p': {
      textAlign: 'center',
    },
  },

  tabHeader: {
    backgroundColor: '#003164',
    '& div': {
      display: 'flex',
      justifyContent: 'center',
    },
  },

  tabHeaderReverse: {
    backgroundColor: '#003164',
    transform: 'rotateY(180deg)',
  },

  indicator: {
    backgroundColor: 'white',
  },

  dropzone: {
    borderRadius: '4px',
    width: '100%',
    minHeight: '150px',
    padding: '20px 0',
    backgroundColor: 'rgba(78, 78, 78, 0.1)',
    border: '2px dashed rgba(78, 78, 78, 0.4)',
    flexShrink: '0',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'all 0.1s linear',
  },

  onDrag: {
    borderRadius: '4px',
    width: '100%',
    minHeight: '150px',
    padding: '20px 0',
    backgroundColor: 'rgba(78, 78, 78, 0.1)',
    flexShrink: '0',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'all 0.1s linear',
    border: '2px dashed #003164',
    boxShadow: '0px 10px 100px #e7f0f7',
  },
  fileUploadButtonsContainer: {
    display: "flex",
    justifyContent: "space-between",
    padding: "0 24px"
  },

  button: {
    backgroundColor: '#003164',

    '&:hover': {
      backgroundColor: '#003164',
    },
  },

  removeButton: {
    backgroundColor: 'none',
    color: '#003164',
  },

  file: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: "0 24px"
  },

  fileError: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: '1px solid red',
    borderRadius: '4px',
    margin: '5px 0',
  },

  fileName: {
    display: 'flex',
    alignItems: 'center',
  },

  fileNameRevert:{
    display: 'flex',
    alignItems: 'center',
    transform: 'rotateY(180deg)'
},

  report: {
    maxWidth: 'fit-content',
    textAlign: 'left',
  },
} ));
