import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { SessionStorageService } from '../shared/services/session-storage';

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: {
        language: 'Language:',
        'eng-lang-button': 'EN',
        'heb-lang-button': 'HEB',
        'e-mail': 'E-mail',
        password: 'Password',
        'forgot-password': 'Forgot password?',
        'sign-in': 'Sign In',
        'otp-sign-in': 'Sign In With OTP',
        'email-sign-in': 'Sign In With Email And Password',
        'monthly-overview-upper-case': 'MY FILES',
        'all-files-upper-case': 'ALL FILES',
        'all-files': 'All Files',
        'employees': 'Employees',
        files: 'Files',
        invoices: 'Invoices',
        expenses: 'Expenses',
        salaries: 'Salaries',
        'profile-info': 'My Profile',
        name: 'Name',
        phone: 'Phone',
        'created-date': 'Created Date',
        type: 'Type',
        customer: 'Customer',
        'total-nis': 'Total (Nis)',
        'total-paid': 'Total Paid:',
        'total-estimation': 'Total Estimation:',
        nis: 'NIS',
        'my-salary': 'Salary',
        'monthly-overview': 'My files',
        invoice: 'Invoice',
        receipt: 'Receipt',
        'invoice/receipt': 'Invoice/Receipt',
        back: 'Back',
        'company-name': 'Company name',
        'street-address': 'Street Address',
        city: 'City',
        'email-address': 'Email Address',
        'phone-number': 'Phone: (000) 000-00-00',
        'invoice-number': 'Invoice #',
        'bill-to': 'Bill To:',
        description: 'Description',
        'service-fee': 'Service Fee',
        labor: 'Labor:',
        'hours-at': 'hours at',
        hr: 'hr',
        'new-client-discount': 'New Client Discount',
        tax: 'Tax: 4.25% (after discount)',
        thanks: 'Thank you for your business!',
        total: 'Total',
        'auth-error': 'Wrong e-mail or password.',
        'identity-number': 'Personal ID',
        'dont-have-account': 'Don’t have an account?',
        'already-have-account': 'Already have an account?',
        'contact-with-us': 'Contact with us for help',
        'sign-up': 'Sign Up',
        'otp-sign-up': 'Sign Up With OTP',
        'email-sign-up': 'Sign Up With Email And Password',
        'or-string': 'OR',
        'one-time-code': "Code",
        'get-one-time-password': 'Get One Time Password',
        'send-password-again': 'Send password again',
        'got-password': "Haven’t got password?",
        'get-code': 'Get Code',
        'use-regular-password': 'Use Regular Password',
        'code': 'Code',
        'didnt-get-code': 'Didn\'t get the code?',
        'resend': 'Resend',
        job: 'Job',
        'update-details': 'Update details',
        'sign-out': 'Sign Out',
        January: 'January',
        February: 'February',
        March: 'March',
        April: 'April',
        May: 'May',
        June: 'June',
        July: 'July',
        August: 'August',
        September: 'September',
        October: 'October',
        November: 'November',
        December: 'December',
        'part-number': 'Part Number',
        'unit-price': 'Unit Price',
        'total-price': 'Total Price',
        qty: 'Qty',
        'sub-total': 'Sub Total',
        discount: 'Discount',
        vat: 'Vat of 17%',
        number: 'Number',
        'company-general-title':
          'Yeul Sachir Mechkar Upituach Ltd.- YS Research & Development Ltd.',
        'company-address':
          'Address: 16 King George St. 3rd Entrance, P.O.B 7992, Jerusalem 9107901,',
        'company-phone-fax': 'Phone: +972-2-6510666 Fax: +972-2-6510161',
        'company-dealer': 'Licensed Dealer: 514129717',
        'invoice-note-row-1':
          'Please either send acheck to YEULS SACHIR RES AND DEV LTD P.O.B 7992 JERUSALEM 91079 OR',
        'invoice-note-row-2':
          'For that purpose, we have opened a local bank account in the USA.',
        'invoice-note-row-3':
          'Please use the bellow bank details for your future transfers.',
        'invoice-note-row-4':
          'For local ACH transfer: Bank Name First Century Bank routing (ABA)061120084 Account Number 4024140610925.',
        'invoice-note-row-5':
          'Account Type CHECKING Beneficiary Name YEUL SACHIR RES AND DEV LTD.',
        'invoice-note-row-6':
          'For swift transfer: Bank Name: Metropolitan Commercial Bank.',
        'invoice-note-row-7':
          'Bank address 99 Park Avenue 4th Floor, New York, NY 10016 Fedwire ABA number 026013356.',
        'invoice-note-row-8':
          'Account Number 8799004505 Beneficiary Name YEUL SACHIR RES AND DEV LTD.',
        'home-page-title': 'Welcome to Yeul Sachir',
        'home-page': 'Home Page',
        'contact-us-title': 'Contact Us',
        'general-manager': 'Rodan G - General Manager',
        'office-manager': 'Rima Z - Office Manager. Salaries, Banking',
        'payroll-accounting': 'Yochi L - Payroll Accounting',
        billing: 'Oshrat L - Billing, Expenses, Collection',
        'accounting-manager': 'Oshrat L - Billing, Expenses, Collection',
        'savings-pension': 'Rivka G. - Savings and Pension Plans',
        'operations-manager': 'Charley B.-  Operations Manager',
        'yeul-sachir-title': 'Yeul Sachir',
        'mailing-address-title': 'Mailing Address:',
        'mailing-address': 'POB 7992 Jerusalem Israel 9107901',
        'office-address-title': 'Office Address:',
        'office-address':
          '16 King George Street, Entrance C, 10th Floor, Jerusalem, Israel',
        telephone: 'Telephone:',
        fax: 'Fax:',
        'bank-info-title': 'Bank Information:',
        'bank-title-1': 'Yeul Sachir Mechkar UPituach Ltd',
        'bank-mizrachi-tefachot': 'Bank Mizrachi Tefachot',
        'bank-number-20': 'Bank #20',
        'bank-brach-number': 'Talpiot Branch 517',
        'bank-account-number-1': 'Account #188832',
        'bank-title-2': 'Yeul Sachir',
        'bank-account-number-2': 'Account #103888',
        'mail-info-title': 'Mail Information:',
        'monthly-summary': 'Monthly Summary',
        'us-bank-details': 'U.S bank details',
        'no-files': 'No Files',
        'search-by-user-id': 'Search by user id',
        search: 'Search',
        'search-helper-text':
          '*search by user identity number to view his files',
        'not-complite-registration': 'User didn’t complete his registration',
        'send-instructions': 'Send Instructions',
        'reset-password': 'Reset password',
        'reset-password-text':
          "Enter the email associated with your account and we'll send an email with instructions to reset your password",
        'check-your-email-instructions':
          'We have sent a password recover instructions to your email',
        'check-your-email': 'Check your mail',
        'check-spam': 'Did not receive the email? Check your spam filter, or',
        'try-another-email': 'try another email address',
        'create-password': 'Create password',
        'origin-amount': 'Total',
        rate: 'Rate',
        'currency-type': 'Currency',
        'monthly-files': 'Monthly Files',
        'upload-files': 'Upload Files',
        'drag-n-drop':
          "Drag 'n' drop some files here, or click to select files",
        'drag-n-drop-format': 'Files should be in the following format only:',
        'file-number': 'File number',
        'file-name': 'File name',
        'file-type': 'File type',
        user: 'User',
        'missed-files': 'Missed Files',
        'notes': 'Notes',
        'id': 'ID',
        'agent-id': 'Agent ID',
        'email': 'Email',
        'status': 'Status',
        'active': 'Active',
        'dismissed': 'Dismissed',
        'company-id': 'Company ID',
        'with-capital-employees': 'Accounts',
        'agents-list': 'Private Agents',
        'companies-list': 'Company Agents',
        'search-by-all-fields': 'Search by Employee Name, Company Name, ID, Agent ID, Email',
        'monthly-report': 'Monthly Report',
        'days': 'Days',
        'cost': 'Cost',
        'fee': 'Fee',
        'daily-rate-with-colon': 'Daily Rate:',
        'hourly-rate-with-colon': 'Hourly rate:',
        'daily-off-rate': 'Daily Off Rate:',
        'exchange-rate': 'Exchange Rate:',
        'total-cost-with-colon': 'Total cost:',
        'total-fee': 'Total fee:',
        'send-report': 'Send Report',
        'profile': 'Profile',
        'date': 'Date',
        'note': 'Note',
        'amount': 'Amount',
        'half-day-50': 'Half Day 50%',
        'transit-60': 'Transit 60%',
        'day-100': 'Day 100%',
        'day-off-150': 'Day Off 150%',
        'cancel': 'Cancel',
        'confirm': 'Confirm',
        'validate-and-approve': 'Validate & Approve',
        'open': 'Open',
        'closed': 'Closed',
        'ready': 'Ready',
        'in-progress': 'In progress',
        'admin-panel': 'Admin Panel',
        'firstname': 'Firstname',
        'lastname': 'Lastname',
        'create-user': 'Create user',
        'admin': 'Admin',
        'role': 'Role',
        'company': 'Company',
        'create-company': 'Create company',
        'is-use-daily-rate': 'Is use daily rate',
        'hourly-rate': 'Hourly rate',
        'daily-rate': 'Daily Rate',
        'employee': 'Employee',
        'manager': 'Manager',
        'create-company-user': 'Create company user',
        'done': 'Done',
        'pending': 'Pending',
        'hours': 'Hours',
        'currency': 'Currency',
        'include-vat-17': 'Include VAT (17%)',
        'create-invoice': 'Create invoice',
        'send-on-email': 'Send on Email?',
        'yes': 'Yes',
        'no': 'No',
        '1st': '1st',
        '9st': '9st',
        'total-income': 'Total income',
        'total-expense': 'Total expense',
        'amount-vat-17': 'Amount + VAT (17%)',
        'paid-by': 'Paid By',
        'total-income-with-colon': 'Total income:',
        'total-outcome-with-colon': 'Total outcome:',
        'add-income': 'Add Income',
        'split-income': 'Split Income',
        'split-outcome': 'Split outcome',
        'reset-salary': 'Reset Salary',
        'save-salary': 'Save Salary',
        'edit-user': 'Edit User',
        'actions': 'Actions',
        'update-user': 'Update User',
        'user-terms': 'User Terms',
        'yeuls-fee': 'Yeuls Fee (0-100%)',
        'salary-date': 'Salary Date',
        'calculation-type': 'Calculation Type',
        'max-yeuls-fee': 'Max Yeuls Fee',
        'first': '1th',
        'ninth': '9th',
        'gross': 'Gross',
        'edit-company': 'Edit Company',
        'update-company': 'Update Company',
        'company-terms': 'Company Terms',
        'update-company-user': 'Update Company User',
        'create-csv': 'Create Csv',
        'search-by-name-and-id': 'Search by Employee Name, Agent ID',
        'paid': 'Paid',
        'not-paid': 'Not Paid',
        'total-fee-cost': "Total fee cost",
        'total-cost': 'Total cost',
        'total-net': 'Total net',
        'export-to-csv': 'Export to CSV',
        'import-csv': 'Import CSV',
        'oketz-number': 'Oketz Number',
        'country': 'Country',
        'address': 'Address',
        'car-details': 'Car Details',
        'report-type': 'Report Type',
        'start-date': 'Start Date',
        'end-date': 'End Date',
        'bank-name': 'Bank Name',
        'bank-number': 'Bank Number',
        'bank-branch-number': 'Bank Branch Name',
        'bank-account-number': 'Bank Account Name',
        'insurance-notes': 'Insurance Notes',
        'next': 'Next',
        'hourly': 'Hourly',
        'daily': 'Daily',
        'fixed': 'Fixed',
        'general': 'General',
        'terms-and-conditions': 'Terms And Conditions',
        'bank': 'Bank',
        'insurance': 'Insurance',
        'edit': 'Edit',
        'save': 'Save',
        'santec-account-id': 'Santec Account Id',
        'default-daily-rate': 'Default Daily Rate',
        'companies': 'Companies',
        'user-companies-empty-list': 'User doesn\'t have companies!!!',
        'included-vat': 'Included Vat',
        'users': 'Users',
        'report-fee': 'Report fee',
        'heb': 'HEB',
        'eng': 'ENG',
        'USD': 'USD',
        'NIS': 'NIS',
        'EUR': 'EUR',
        'registration-fee': 'Registration Fee',
        'registration-fee-paid': 'Registration Fee Paid',
        'registration-fee-free': 'Registration Fee Free',
        'clients-list': 'Clients',
        'suppliers-list': 'Suppliers',
        'create-client': 'Create client',
        'create-supplier': 'Create supplier',
        'client-name': 'Client name',
        'business-number': 'Business number',
        'withholding-tax': 'Withholding tax',
        'withholding-tax-expiration': 'Withholding tax expiration',
        'general-ledger': 'General ledger',
        'zip': 'ZIP',
        'created-by': 'Created by',
        'agents-count': 'Agents count',
        'to-search-message': 'Please start typing to see the result',
        'client-companies-empty-list': 'Client doesn\'t have companies!!!',
        'client-users-empty-list': 'Client doesn\'t have users!!!',
        'supplier-companies-empty-list': 'Supplier doesn\'t have companies!!!',
        'supplier-users-empty-list': 'Supplier doesn\'t have users!!!',
        'supplier-name': 'Supplier name',
        'accounts': 'Accounts',
        'links': 'Links',
        'clients': 'Clients',
        'suppliers': 'Suppliers',
        'user-clients-empty-list': 'User doesn\'t have clients!!!',
        'user-suppliers-empty-list': 'User doesn\'t have suppliers!!!',
        'company-clients-empty-list': 'Company doesn\'t have clients!!!',
        'company-suppliers-empty-list': 'Company doesn\'t have suppliers!!!',
        'employees-list': 'Employees list',
        'user-updated': 'User updated!!!',
        'user-created': 'User created!!!',
        'company-updated': 'Company updated!!!',
        'company-created': 'Company created!!!',
        'client-updated': 'Client updated!!!',
        'client-created': 'Client created!!!',
        'supplier-updated': 'Supplier updated!!!',
        'supplier-created': 'Supplier created!!!',
        'something-went-wrong': 'Something went wrong!!!',
        'sync-agents': 'Sync agents',
        'sure-sync': 'Are you sure want sync?',
        'agents-merged-successfully': 'Agents merged successfully',
        'transform-to-company': 'Transform to company',
        'sure-transform': 'Are you sure want transform private agent to company?',
        'agents-transformed-successfully': 'Agents transformed successfully',
        'search-by-name-account-business-number': 'Search by Name, Account Id, Business Number',
        'agents': 'Agents',
        'client-number': 'Client Number',
        'supplier-number': 'Supplier Number',
        'user-number': 'User Number',
        'company-number': 'Company Number',
        'push-agents': 'Push agents',
        'agents-pushed-successfully': 'Agents pushed successfully',
        'sure-push': 'Are you sure want push?',
        'reset': 'Reset',
        'sync-docs': 'Sync Documents',
        'sync-storage-docs': 'Sync Storage To Documents',
        'docs-merged-successfully': 'Documents Merged Successfully',
        'documents': 'Documents',
        'receipts': 'Receipts',
        'attachments': 'Attachments',
        'receipt-number': 'Receipt number',
        'supplier': 'Supplier',
        'due-date': 'Due date',
        'negdi': 'Negdi',
        'nogl': 'Nogl',
        'yeuls': 'Yeuls',
        'agent': 'Agent',
        'hide-not-paid-income': 'Hide not paid income',
        'show-not-paid-income': 'Show not paid income',
        'hide-not-paid-outcome': 'Hide not paid outcome',
        'show-not-paid-outcome': 'Show not paid outcome',
        'salary': 'Salary',
        'year': 'Year',
        'month': 'Month',
        'client': 'Client',
        'import-salaries': 'Import salaries for ',
        'sure-import': 'You going to import salaries for {0}. Are you sure?',
        'import-finished': 'Import finished',
        'oketz-cost': 'Oketz cost',
        'oketz-net': 'Oketz net',
        'oketz-net-to-pay': 'Oketz net to pay',
        'monthly-link-table-header': 'M',
        'salary-link-table-header': 'S',
        'select-import-month': 'Please select import month',
        'sync-salaries': 'Sync salaries',
        'salaries-merged-successfully': 'salaries-merged-successfully',
        'balance': 'Balance',
        'record-type':'Type',
        'amount-before-vat':'Before vat',
        'delete': 'Delete',
      },
    },
    heb: {
      translation: {
        language: 'שפה',
        'eng-lang-button': 'אנגלית',
        'heb-lang-button': 'עברית',
        'e-mail': 'אימייל',
        password: 'סיסמה',
        'forgot-password': '?שכחת סיסמה',
        'sign-in': 'התחבר',
        'otp-sign-in': 'התחברות עם סיסמה חד פעמית',
        'otp-sign-up': 'הרשמה עםסיסמה  חד פעמית',
        'email-sign-in': 'התחברות עם אימייל וסיסמה',
        'email-sign-up': 'הרשמה עם אימייל וסיסמה',
        'or-string': 'או',
        'one-time-code': 'סיסמה חד פעמית',
        'get-code': 'שליחת קוד',
        'get-one-time-password': "שליחת קוד",
        'got-password': "סיסמה התקבלה",
        'send-password-again': 'שליחה חוזרת',
        'use-regular-password': 'שימוש בסיסמה רגילה',
        'code': 'קוד חד פעמי',
        'didnt-get-code': 'קוד לא התקבל',
        'resend': 'שליחה חוזרת',
        'monthly-overview-upper-case': 'מסמכים',
        'all-files': 'כל המסמכים',
        'all-files-upper-case': 'כל המסמכים',
        'employees': 'סוכנים',
        files: 'מסמכים',
        invoices: 'חשבוניות',
        expenses: 'הוצאות',
        salaries: 'תלושי שכר',
        'profile-info': 'פרופיל',
        name: 'שם',
        phone: 'טלפון',
        'created-date': 'תאריך',
        type: 'סוג',
        customer: 'לקוח',
        'total-nis': 'סכום בשקלים',
        'total-paid': 'סה"כ שולם',
        'total-estimation': ':סה"כ משוער',
        nis: 'ש"ח',
        'my-salary': 'תלוש שכר',
        'monthly-overview': 'מסמכים',
        invoice: 'חשבונית מס',
        receipt: 'קבלה', // translate it pls
        'invoice/receipt': 'חשבונית מס/קבלה',
        back: 'חזור',
        'company-name': 'שם חברה',
        'street-address': 'כתובת',
        city: 'עיר',
        'email-address': 'אימייל',
        'phone-number': 'טלפון',
        'invoice-number': 'מספר חשבונית',
        'bill-to': ':עבור',
        description: 'תיאור',
        'service-fee': 'עמלה',
        labor: 'זמן עבודה',
        'hours-at': 'שעות',
        hr: 'כח אדם',
        'new-client-discount': 'הנחה',
        tax: 'מע"מ',
        thanks: '!תודה',
        total: 'סה"כ',
        'auth-error': 'שגיאה בהתחברות, נא נסה שנית',
        // TODO: replace value to Personal ID
        'identity-number': 'ת.ז',
        'dont-have-account': '?אין לך חשבון',
        'already-have-account': '?כבר רשום',
        'contact-with-us': 'פנה אלינו',
        'sign-up': 'הירשם',
        job: 'מקצוע',
        'update-details': 'עדכן פרטים',
        'sign-out': 'יציאה',
        January: 'ינואר',
        February: 'פברואר',
        March: 'מרץ',
        April: 'אפריל',
        May: 'מאי',
        June: 'יוני',
        July: 'יולי',
        August: 'אוגוסט',
        September: 'ספטמבר',
        October: 'אוקטובר',
        November: 'נובמבר',
        December: 'דצמבר',
        'part-number': 'מק"ט', // Part Number
        'unit-price': 'מחיר', // Unit Price
        'total-price': 'סה"כ', // Total Price
        qty: 'כמות', // Qty
        'sub-total': 'לפני מע"מ', // Sub Total
        discount: 'הנחה', // Discount
        vat: 'מע"מ', // Vat of 17%
        number: 'אסמכתא', // Number
        'company-general-title': '‫בע"מ‬ ‫ופיתוח‬ ‫מחקר‬ ‫שכיר‬ ‫יעול‬ ‫י‪.‬ש‬',
        'company-address': `‫‪9107901‬‬ ‫ירושלים‬ ‫‪,7992‬‬ ‫ת‪.‬ד‪.‬‬ ‫ג‪,‬‬ ‫כניסה‬ ‫‪16‬‬ ‫ג'ורג‬ ‫המלך‬ ‫רחוב‬ ‫כתובת‪:‬‬`,
        'company-phone-fax':
          '‫‪073-2456-508‬‬ ‫טלפון‪:‬‬ ‫‪073-2456522‬‬ ‫פקס‪:‬‬',
        'company-dealer': '‫‪514129717‬‬ ‫מורשה‪:‬‬ ‫עוסק‬',
        'invoice-note-row-1': `‫למעלה‬ ‫הרשום‬ ‫לת‪.‬ד‬ ‫בצ'ק‬ ‫בע"מ"‬ ‫ופיתוח‬ ‫מחקר‬ ‫שכיר‬ ‫ל"ייעול‬ ‫לשלוח‬ ‫יש‬ ‫חשבוניות‬ ‫עבור‬ ‫תשלומים‬`,
        'invoice-note-row-2':
          '‫‪517‬‬ ‫סניף‬ ‫‪188832‬‬ ‫חשבון‬ ‫טפחות‬ ‫מזרחי‬ ‫לבנק‬ ‫בנקאית‬ ‫העברה‬ ‫לבצע‬ ‫או‬',
        'invoice-note-row-3':
          '‫השירות‬ ‫נותן‬ ‫שם‬ ‫או‬ ‫החשבונית‬ ‫מספר‬ ‫את‬ ‫ההעברה‬ ‫בפרטי‬ ‫לכלול‬ ‫יש‬ ‫העברה‬ ‫ביצוע‬ ‫בעת‬',
        'invoice-note-row-4': `‫‪vered@yeuls.co.il‬‬ ‫למייל‬ ‫צ'ק‬ ‫הפקדת‬ ‫אישור‬ ‫או‬ ‫העברה‬ ‫אישור‬ ‫אלינו‬ ‫לשלוח‬ ‫חובה‬`,
        'invoice-note-row-5': '‫תודה‬',
        'invoice-note-row-6': '‫!‬ ‫במקור‬ ‫מניכוי‬ ‫מלא‬ ‫פטור‬',
        'invoice-note-row-7': '',
        'invoice-note-row-8': '',
        'home-page-title': 'ברוך הבא ליעול שכיר',
        'home-page': 'ראשי',
        'contact-us-title': 'יצירת קשר',
        'general-manager': 'רודן ג. - מנכ"ל',
        'office-manager': 'רימה ז. – מנהלת יעול שכיר , משכורות , בנקים',
        'payroll-accounting': 'יוכי ל. - חשבת שכר',
        billing: 'אושרת ל. –חשבוניות , הוצאות , גביה',
        'accounting-manager': 'ענבל כ. הנהלת חשבונות לעצמאים',
        'savings-pension': 'רבקה ג. – רפרנטית חסכון ופנסיה',
        'operations-manager': 'צארלי ב. - מנהל תפעול',
        'yeul-sachir-title': 'יעול שכיר',
        'mailing-address-title': ':כתובת למשלוח דואר',
        'mailing-address': 'ת.ד. 7992 ירושלים 9107901',
        'office-address-title': ':כתובתנו החדשה',
        'office-address': 'המלך גורג 16 כניסה ג קומה 10, ירושלים',
        telephone: ':טלפון',
        fax: ':פקס',
        'bank-info-title': 'פרטי בנק',
        'bank-title-1': 'יעול שכיר מחקר ופיתוח בע"מ',
        'bank-mizrachi-tefachot': 'בנק מזרחי טפחות',
        'bank-number-20': 'בנק 20',
        'bank-brach-number': 'בנק מזרחי סניף 517',
        'bank-account-number-1': 'חשבון 188832',
        'bank-title-2': 'יעול שכיר בע"מ',
        'bank-account-number-2': 'חשבון 103888',
        'mail-info-title': 'כתובת',
        'monthly-summary': 'ריכוז חודשי',
        'us-bank-details': 'פרטי בנק ארה"ב',
        'no-files': 'אין מסמכים',
        'search-by-user-id': 'חיפוש לפי ת.ז',
        search: 'חיפוש',
        'search-helper-text': 'חיפוש לפי ת.ז או שם עובד',
        'not-complite-registration': 'העובד טרם השלים את הרשמתו לפורטל',
        'send-instructions': 'אישור ושליחה',
        'reset-password': 'איפוס סיסמה',
        'reset-password-text': 'יש להזין כתובת מייל כפי שהיא רשומה במערכת',
        'check-your-email-instructions': 'שלחנו אלייך מייל עם הוראות',
        'check-your-email': 'נשלח אליך מייל',
        'check-spam': 'אם לא קיבלת מייל',
        'try-another-email': 'נסה שנית',
        'create-password': 'יצירת סיסמה חדשה',
        'origin-amount': 'סכום',
        rate: 'שער',
        'currency-type': 'מטבע',
        'monthly-files': 'ריכוזים חודשיים',
        'upload-files': 'העלאת קבצים',
        'drag-n-drop': 'העלאת קבצים לפה על ידי גרירה או בחירה',
        'drag-n-drop-format': 'יש להעלות קבצים בפורמט הבא בלבד:',
        'file-number': 'מספר',
        'file-name': 'שם',
        'file-type': 'סוג',
        user: 'שם סוכן',
        'missed-files': 'מסמכים חסרים',
        'notes': 'הערות',
        'id': 'ת.ז',
        'agent-id': 'מספר סוכן',
        'email': 'אימייל',
        'status': 'סטטוס',
        'active': 'פעיל',
        'dismissed': 'לא פעיל',
        'company-id': 'מספר חברה',
        'with-capital-employees': 'עובדים',
        'agents-list': 'סוכנים',
        'companies-list': 'חברות',
        'search-by-all-fields': 'חיפוש לפי, שם, ת.ז, מספר סוכן, אימייל',
        'monthly-report': 'דו"ח חודשי',
        'days': 'ימים',
        'cost': 'עלות',
        'fee': 'עמלה',
        'daily-rate-with-colon': 'תעריף יומי: ',
        'hourly-rate-with-colon': 'תעריף שעתי: ',
        'daily-off-rate': 'שבת \ חג:',
        'exchange-rate': 'שער חליפין:',
        'total-cost-with-colon': 'סה"כ עלות',
        'total-fee': 'סה"כ עמלה:',
        'send-report': 'שליחת דו"ח',
        'profile': 'פרופיל',
        'date': 'תאריך רישום',
        'note': 'פרטים',
        'amount': 'סכום אחרי מע"מ',
        'half-day-50': 'חצי יום 50%',
        'transit-60': 'טרנזיט 60%',
        'day-100': 'יום רגיל 100%',
        'day-off-150': 'יום חופש 150%',
        'cancel': 'ביטול',
        'confirm': 'אישור',
        'validate-and-approve': 'בדיקה ואישור',
        'open': 'פתוח',
        'closed': 'סגור',
        'ready': 'מוכן',
        'in-progress': 'בטיפול',
        'admin-panel': 'איזור מנהל',
        'firstname': 'שם פרטי',
        'lastname': 'שם משפחה',
        'create-user': 'יצירת סוכן',
        'admin': 'אדמין',
        'role': 'סוג משתמש',
        'company': 'חברה',
        'create-company': 'יצירת חברה',
        'is-use-daily-rate': 'האם תעריף יומי?',
        'hourly-rate': 'תעריף שעתי',
        'daily-rate': 'תעריף יומי',
        'employee': 'עובד',
        'manager': 'מנהל',
        'create-company-user': 'יצירת סוכן',
        'done': 'הסתיים',
        'pending': 'ממתין',
        'hours': 'שעות',
        'currency': 'מטבע',
        'include-vat-17': 'כולל מע"מ 17%',
        'create-invoice': 'הפקת חשבונית',
        'send-on-email': 'האם לשלוח אימייל?',
        'yes': 'כן',
        'no': 'לא',
        '1st': '1 לחודש',
        '9st': '9 לחודש',
        'total-income': 'סה"כ הכנסות',
        'total-expense': 'סה"כ הוצאות',
        'amount-vat-17': 'כולל מע"מ 17%',
        'paid-by': 'שולם ע"י',
        'total-income-with-colon': ':סה"כ הכנסות',
        'total-outcome-with-colon': ':סה"כ הוצאות',
        'add-income': 'השלמת הכנסה',
        'split-income': 'פיצול הכנסה',
        'split-outcome': 'פיצול הוצאה',
        'reset-salary': 'איפוס',
        'save-salary': 'שמירה',
        'edit-user': 'עריכה',
        'actions': 'פעולות',
        'update-user': 'עדכון פרטי סוכן',
        'user-terms': 'פרטי העסקה',
        'yeuls-fee': '(0-100%) עמלה',
        'salary-date': 'תאריך השכר',
        'calculation-type': 'סוג החישוב',
        'max-yeuls-fee': 'מקסימום עמלה',
        'first': '1 לחודש',
        'ninth': '9 לחודש',
        'gross': 'לפי ברוטו',
        'edit-company': 'עריכה',
        'update-Company': 'עדכון פרטי החברה',
        'company-terms': 'תנאי העסקה ותשלום',
        'update-company-user': 'עדכון סוכן בחברה',
        'create-csv': 'ייצוא לאקסל',
        'search-by-name-and-id': 'חיפוש לפי שם הסוכן, מספר סוכן בעמית',
        'paid': 'שולם',
        'not-paid': 'לא שולם',
        'total-fee-cost': 'סה"כ עמלה',
        'total-cost': 'עלות שכר',
        'total-net': 'נטו לתשלום',
        'export-to-csv': 'ייצוא לעוקץ',
        'import-csv': 'ייבוא מעוקץ',
        'oketz-number': 'מספר סוכן בעוקץ',
        'country': 'מדינה',
        'address': 'רחוב ומספר',
        'car-details': 'פרטי רכב',
        'report-type': 'סוג דוח',
        'start-date': 'תאריך תחילת עבודה',
        'end-date': 'תאריך סיום עבודה',
        'bank-name': 'שם בנק',
        'bank-number': 'מספר בנק',
        'bank-branch-number': 'מספר סניף',
        'bank-account-number': 'מספר חשבון בנק',
        'insurance-notes': 'הערות לביטוח',
        'next': 'הבא',
        'hourly': 'שכר עתי',
        'daily': 'שכר יומי',
        'fixed': 'שכר גלובלי',
        'general': 'פרטים כללים',
        'terms-and-conditions': 'תנאים ופרטי העסקה',
        'bank': 'פרטי בנק',
        'insurance': 'ביטוח',
        'edit': 'עריכה',
        'save': 'שמירה',
        'santec-account-id': 'מספר סוכן בעמית',
        'default-daily-rate': 'תעריך יומי',
        'companies': 'חברות',
        'user-companies-empty-list': 'סוכן אינו משויך לאף חברה',
        'included-vat': 'כולל מע"מ',
        'users': 'סוכנים',
        'report-fee': 'עמלה',
        'heb': 'עברית',
        'eng': 'אנגלית',
        'USD': 'דולר',
        'NIS': 'שקל',
        'EUR': 'יורו',
        'registration-fee': 'דמי רישום',
        'registration-fee-paid': 'דמי רישום שולמו',
        'registration-fee-free': 'פטור מדמי רישום',
        'clients-list': 'לקוחות',
        'suppliers-list': 'ספקים',
        'create-client': 'לקוח חדש',
        'create-supplier': 'ספק חדש',
        'client-name': 'שם לקוח',
        'business-number': 'ח.פ \ מספר עוסק',
        'withholding-tax': 'ניכוי במקור',
        'withholding-tax-expiration': 'תוקף ניכוי במקור',
        'general-ledger': 'תוקף ניהול ספרים',
        'zip': 'מיקוד',
        'created-by': 'נוצר על יד',
        'agents-count': 'מספר סוכנים',
        'to-search-message': 'חיפוש',
        'client-companies-empty-list': 'לא קיימים חשבונות מחוברים',
        'client-users-empty-list': 'לא קיימים חשבונות מחוברים',
        'supplier-companies-empty-list': 'לא קיימים חשבונות מחוברים',
        'supplier-users-empty-list': 'לא קיימים חשבונות מחוברים',
        'supplier-name': 'שם ספק',
        'accounts': 'חשבונות',
        'links': 'חשבונות מחוברים',
        'clients': 'לקוחות',
        'suppliers': 'ספקים',
        'user-clients-empty-list': 'לסוכן אין לקוחות משוייכים',
        'user-suppliers-empty-list': 'לסוכן אין ספקים משוייכים',
        'company-clients-empty-list': 'לחברה אין לקוחות משוייכים',
        'company-suppliers-empty-list': 'לחברה אין ספקים משוייכים',
        'employees-list': 'חשבונות',
        'user-updated': 'סוכן עודכן בהצלחה',
        'user-created': 'סוכן הוקם בהצלחה',
        'company-updated': 'סוכן-חברה עודכן בהצלחה',
        'company-created': 'סוכן-חברה הוקם בהצלחה',
        'client-updated': 'לקוח עודכן בהצלחה',
        'client-created': 'לקוח הוקם בהצלחה',
        'supplier-updated': 'ספק עודכן בהצלחה',
        'supplier-created': 'ספק הוקם בהצלחה',
        'something-went-wrong': 'אירעה שגיאה',
        'sync-agents': 'סנכרון חשבונות מעמית',
        'sure-sync': 'אישור פעולת סנכרון חשבונות',
        'agents-merged-successfully': 'סוכנום סונכרנו בהצלחה',
        'transform-to-company': 'הפיכת סוכן לחברה',
        'sure-transform': 'פעולה זו תהפוך סוכן לחברה האם אתה בטוח?',
        'agents-transformed-successfully': 'סוכנים עודכנו בהצלחה',
        'search-by-name-account-business-number': 'חיפוש לפי שם, מספר סוכן, עוסק מורשה',
        'agents': 'סוכנים',
        'client-number': 'מספר לקוח',
        'supplier-number': 'מספר ספק',
        'user-number': 'מספר סוכן',
        'company-number': 'עוסק מורשה',
        'push-agents': 'סנכרון סוכנים לעמית',
        'agents-pushed-successfully': 'סוכנים עודכנו בהצלחה בעמית',
        'sure-push': 'פעולה זו תיצור סוכנים בעמית לחיצה לאישור סנכרון',
        'reset': 'איפוס',
        'sync-docs': 'סנכרון מסמכים',
        'sync-storage-docs': 'סנכרון קישורים למסמכים',
        'docs-merged-successfully': 'מסמכים הועלו בהצלחה',
        'documents': 'מסמכים',
        'receipts': 'קבלות',
        'attachments': 'קבצים מצורפים',
        'receipt-number': 'מספר קבלה',
        'supplier': 'ספק',
        'due-date': 'תאריך ערך',
        'negdi': 'נגדי',
        'nogl': 'Nogl',
        'yeuls': 'יעול שכיר',
        'agent': 'סוכן',
        'hide-not-paid-income': 'הסתר חשבוניות פתוחות',
        'show-not-paid-income': 'כולל חשבוניות פתוחות',
        'hide-not-paid-outcome': 'הסתר הוצאות שלא שולמו',
        'show-not-paid-outcome': 'כולל הוצאות שלא שולמו',
        'salary': 'תלוש שכר',
        'year': 'שנה',
        'month': 'חודש',
        'client': 'לקוח',
        'import-salaries': 'העלאת נתונים מעוקץ',
        'sure-import': 'הינך מעלה נתונים בעבור חודש {0}',
        'import-finished': 'טעינה מעוקץ הסתיימה בהצלחה',
        'oketz-cost': 'עלות מעוקץ',
        'oketz-net': 'נטו מעוקץ',
        'oketz-net-to-pay': 'נטו לתשלום מעוקץ',
        'monthly-link-table-header': 'ריכוז חודשי',
        'salary-link-table-header': 'תלוש',
        'select-import-month': 'יש לבחור חודש',
        'sync-salaries': 'סנכרון ריכוזים חודשיים',
        'salaries-merged-successfully': 'ריכוזים סונכרנו בהצלחה',
        'balance': 'יתרה',
        'record-type':'סוג מסמך',
        'amount-before-vat':'סכום לפני מע"מ',
        'delete': 'מחיקה',

      },
    },
  },
  lng: SessionStorageService.getAppLanguage(),
});
