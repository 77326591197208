import * as _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useSnackbar } from 'notistack';

import './Employees.scss';
import AgentsList from './agents-list';
import CompaniesList from './companies-list';
import { createUser, getUsers, transformUserToCompany, updateUser } from "../../api/requests/users";
import {
  createCompany,
  createCompanyUser,
  deleteCompanyUser,
  getAllCompanies,
  updateCompany,
  updateCompanyUser
} from "../../api/requests/companies";
import Search from "../search";
import YeulsTabs from "../yeuls-tabs";
import Loader from "../loder";
import YeulsButton from "../yeuls-button";
import NewUser from "./agents-list/new-user";
import { ReactComponent as ArrowBackIcon } from "../../shared/icons/arrow-back-icon.svg";
import NewCompany from "./companies-list/new-company";
import UserDetails from "./agents-list/user-details";
import CompanyDetails from "./companies-list/company-details";
import { MenuItem, TextField } from "@material-ui/core";
import { Status } from "../../shared/constants";
import SuppliersList from "./suppliers-list";
import {
  createClient,
  createClientAgent,
  deleteClientAgent,
  getClients,
  updateClient, updateClientAgent
} from "../../api/requests/client";
import {
  createSupplier,
  createSupplierAgent,
  deleteSupplierAgent,
  getSuppliers,
  updateSupplier, updateSupplierAgent
} from "../../api/requests/supplier";
import ClientsList from "./client-list";
import NewClient from "./client-list/new-client";
import ClientDetails from "./client-list/client-details";
import NewSupplier from "./suppliers-list/new-supplier";
import SupplierDetails from "./suppliers-list/supplier-details";
import ConfirmDialog from "../confirm-dialog";
import { mergeAgents, mergeDocs, pushAgents, syncStorageDocuments } from "../../api/requests/yeuls";
import { getAllInvoices, updateInvoice } from "../../api/requests/invoice";
import { getAllReceipts } from "../../api/requests/receipt";
import { getAllExpenses, updateExpense } from "../../api/requests/expense";
import { getAllSalaries } from "../../api/requests/salary";

const tabIds = {
  agentsList: 0,
  companiesList: 1,
  clientsList: 2,
  suppliersList: 3,
}

const Accounts = (props) => {
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [users, setUsers] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [clients, setClients] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const [filteredClients, setFilteredClients] = useState([]);
  const [filteredSuppliers, setFilteredSuppliers] = useState([]);
  const [currentTabId, setCurrentTabId] = useState(tabIds.agentsList);
  const [filterValue, setFilterValue] = useState("");
  //workaround for updating table pagination
  const [updateTable, setUpdateTable] = useState(true);
  const [showLoader, setShowLoader] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [showMergeAgentsConfirmDialog, setShowMergeAgentsConfirmDialog] = useState(false);
  const [showMergeDocsConfirmDialog, setShowMergeDocsConfirmDialog] = useState(false);
  const [showMergeStorageDocsConfirmDialog, setShowMergeStorageDocsConfirmDialog] = useState(false);
  const [showPushConfirmDialog, setShowPushConfirmDialog] = useState(false);
  const [showTransformConfirmDialog, setShowTransformConfirmDialog] = useState(false);
  const tabsConfig = useMemo(() => [
      {
        id: tabIds.agentsList,
        label: "agents-list",
        itemsCount: users?.length,
        defaultSelectedTab: currentTabId === tabIds.agentsList
      }, {
        id: tabIds.companiesList,
        label: "companies-list",
        itemsCount: companies?.length,
        defaultSelectedTab: currentTabId === tabIds.companiesList
      }, {
        id: tabIds.clientsList,
        label: "clients-list",
        itemsCount: clients?.length,
        defaultSelectedTab: currentTabId === tabIds.clientsList
      }, {
        id: tabIds.suppliersList,
        label: "suppliers-list",
        itemsCount: suppliers?.length,
        defaultSelectedTab: currentTabId === tabIds.suppliersList
      }],
    // eslint-disable-next-line
    [users, companies, clients, suppliers, i18n.language, selectedCompany, selectedUser, selectedClient, selectedSupplier]);

  useEffect(() => {
    setShowLoader(true);

    refreshEntities()
      .finally(() => {
        setShowLoader(false);
      });
  }, []); // eslint-disable-line

  useEffect(() => {
    if (!filterValue) {
      setFilteredUsers(users);
      setFilteredCompanies(companies);
      setFilteredClients(clients);
      setFilteredSuppliers(suppliers);
    } else {
      const filterValueToLowerCase = filterValue.toLowerCase();

      setFilteredUsers(_.filter(users, (user) =>
        ( user.firstname?.toLowerCase() || "" ).indexOf(filterValueToLowerCase) !== -1 ||
        ( user.identity_number?.toString() || "" ).indexOf(filterValueToLowerCase) !== -1 ||
        ( user.santec_account_id?.toString() || "" ).indexOf(filterValueToLowerCase) !== -1 ||
        ( user.email?.toLowerCase() || "" ).indexOf(filterValueToLowerCase) !== -1));

      setFilteredCompanies(_.filter(companies, (company) =>
        ( company.company_name?.toLowerCase() || "" ).indexOf(filterValueToLowerCase) !== -1 ||
        ( company.business_number?.toString() || "" ).indexOf(filterValueToLowerCase) !== -1 ||
        ( company.santec_account_id?.toString() || "" ).indexOf(filterValueToLowerCase) !== -1));

      setFilteredClients(_.filter(clients, (client) =>
        ( client.client_name?.toLowerCase() || "" ).indexOf(filterValueToLowerCase) !== -1 ||
        ( client.business_number?.toString() || "" ).indexOf(filterValueToLowerCase) !== -1 ||
        ( client.santec_account_id?.toString() || "" ).indexOf(filterValueToLowerCase) !== -1));

      setFilteredSuppliers(_.filter(suppliers, (supplier) =>
        ( supplier.supplier_name?.toLowerCase() || "" ).indexOf(filterValueToLowerCase) !== -1 ||
        ( supplier.business_number?.toString() || "" ).indexOf(filterValueToLowerCase) !== -1 ||
        ( supplier.santec_account_id?.toString() || "" ).indexOf(filterValueToLowerCase) !== -1));
    }

    setUpdateTable(false);
    setTimeout(() => setUpdateTable(true));
  }, [filterValue, users, companies, clients, suppliers]);

  const refreshEntities = () => {
    return Promise
      .all([getUsers({
        clients: true,
        suppliers: true
      }),
        getAllCompanies(true),
        getClients(),
        getSuppliers(),
        getAllInvoices(),
        getAllReceipts(),
        getAllExpenses(),
        getAllSalaries()])
      .then(([users, companies, clients, suppliers, invoices, receipts, expenses, salaries]) => {
        const updatedUsers = _.map(users, (user) => ( {
          ...user,
          invoices: _.filter(invoices, { user_id: user.id }),
          receipts: _.filter(receipts, { user_id: user.id }),
          expenses: _.filter(expenses, { user_id: user.id }),
          salaries: _.filter(salaries, { user_id: user.id }),
        } ));

        const updatedCompanies = _.map(companies, (company) => ( {
          ...company,
          invoices: _.filter(invoices, { company_id: company.id }),
          receipts: _.filter(receipts, { company_id: company.id }),
          expenses: _.filter(expenses, { company_id: company.id }),
          salaries: _.filter(salaries, { company_id: company.id }),
        } ));

        setUsers(_.orderBy(updatedUsers, ["role", "active", "firstname"], ['asc', 'desc', 'asc']));
        setCompanies(_.orderBy(updatedCompanies, ["company_name"], ['asc']));
        setClients(_.orderBy(clients || [], ['client_name'], ['asc']));
        setSuppliers(_.orderBy(suppliers || [], ['supplier_name'], ['asc']));

        if (selectedUser?.id)
          setSelectedUser(_.find(updatedUsers, { id: selectedUser.id }));

        if (selectedCompany?.id)
          setSelectedCompany(_.find(updatedCompanies, { id: selectedCompany.id }));

        if (selectedClient?.id)
          setSelectedClient(_.find(clients, { id: selectedClient.id }));

        if (selectedSupplier?.id)
          setSelectedSupplier(_.find(suppliers, { id: selectedSupplier.id }));
      })
  }

  const createUserHandler = (user) => {
    setShowLoader(true);
    return createUser(user)
      .then(() => {
        setSelectedUser(null)
        refreshEntities();
      })
      .then(() => {
        enqueueSnackbar(t("user-created"), { variant: 'success' });
      })
      .catch(() => {
        enqueueSnackbar(t("something-went-wrong"), { variant: 'error' });
      })
      .finally(() => {
        setShowLoader(false);
      })
  }

  const updateUserHandler = (user, userCompaniesChanges, userClientsChanges, userSuppliersChanges, userInvoiceChanges, userExpenseChanges) => {
    setShowLoader(true);

    return updateUser(user)
      .then(() => {
        const updatedCompaniesIds = Object.keys(userCompaniesChanges || {});
        const updatedClientsIds = Object.keys(userClientsChanges || {});
        const updatedSuppliersIds = Object.keys(userSuppliersChanges || {});

        return Promise.all([
          ..._.map(updatedCompaniesIds, (companyId) => {
            if (userCompaniesChanges[companyId]?.id)
              return updateCompanyUser(userCompaniesChanges[companyId]);

            if (userCompaniesChanges[companyId] && !userCompaniesChanges[companyId]?.id)
              return createCompanyUser({ ...userCompaniesChanges[companyId], user_id: user.id });

            const userCompanyId = _.find(user.companies, { id: +companyId })?.UserCompany?.id

            if (userCompanyId)
              return deleteCompanyUser(userCompanyId)
          }).filter(Boolean),
          ..._.map(updatedClientsIds, (clientId) => {
            if (userClientsChanges[clientId]?.id)
              return updateClientAgent(userClientsChanges[clientId]);

            if (userClientsChanges[clientId] && !userClientsChanges[clientId]?.id)
              return createClientAgent({ ...userClientsChanges[clientId], user_id: user.id });

            const userClientId = _.find(user.clients, { id: +clientId })?.ClientAgent?.id

            if (userClientId)
              return deleteClientAgent(userClientId)
          }).filter(Boolean),
          ..._.map(updatedSuppliersIds, (supplierId) => {
            if (userSuppliersChanges[supplierId]?.id)
              return updateSupplierAgent(userSuppliersChanges[supplierId]);

            if (userSuppliersChanges[supplierId] && !userSuppliersChanges[supplierId]?.id)
              return createSupplierAgent({ ...userSuppliersChanges[supplierId], user_id: user.id });

            const userSupplierId = _.find(user.suppliers, { id: +supplierId })?.SupplierAgent?.id

            if (userSupplierId)
              return deleteSupplierAgent(userSupplierId)
          }).filter(Boolean),
          ..._.map(userInvoiceChanges, (invoice) => updateInvoice(invoice)),
          ..._.map(userExpenseChanges, (expense) => updateExpense(expense)),
        ]);
      })
      .then(refreshEntities)
      .then(() => {
        enqueueSnackbar(t("user-updated"), { variant: 'success' });
      })
      .catch((e) => {
        enqueueSnackbar(t("something-went-wrong"), { variant: 'error' });
        throw e;
      })
      .finally(() => {
        setShowLoader(false);
      })
  }

  const createCompanyHandler = (company) => {
    setShowLoader(true);
    return createCompany(company)
      .then(() => {
        setSelectedCompany(null)
        refreshEntities();
      })
      .then(() => {
        enqueueSnackbar(t("company-created"), { variant: 'success' });
      })
      .catch(() => {
        enqueueSnackbar(t("something-went-wrong"), { variant: 'error' });
      })
      .finally(() => {
        setShowLoader(false);
      })
  }

  const updateCompanyHandler = (company, companiesUserChanges, companyClientsChanges, companySuppliersChanges, companyInvoiceChanges, companyExpenseChanges) => {
    setShowLoader(true);

    return updateCompany(company)
      .then(() => {
        const updatedUserIds = Object.keys(companiesUserChanges || {});
        const updatedClientsIds = Object.keys(companyClientsChanges || {});
        const updatedSuppliersIds = Object.keys(companySuppliersChanges || {});

        return Promise.all([..._.map(updatedUserIds, (userId) => {
          if (companiesUserChanges[userId]?.id)
            return updateCompanyUser(companiesUserChanges[userId]);

          if (companiesUserChanges[userId] && !companiesUserChanges[userId]?.id)
            return createCompanyUser({ ...companiesUserChanges[userId], company_id: company.id });

          const userCompanyId = _.find(company.users, { id: +userId })?.UserCompany?.id

          if (userCompanyId)
            return deleteCompanyUser(userCompanyId)
        }).filter(Boolean),
          ..._.map(updatedClientsIds, (clientId) => {
            if (companyClientsChanges[clientId]?.id)
              return updateClientAgent(companyClientsChanges[clientId]);

            if (companyClientsChanges[clientId] && !companyClientsChanges[clientId]?.id)
              return createClientAgent({ ...companyClientsChanges[clientId], company_id: company.id });

            const userClientId = _.find(company.clients, { id: +clientId })?.ClientAgent?.id

            if (userClientId)
              return deleteClientAgent(userClientId)
          }).filter(Boolean),
          ..._.map(updatedSuppliersIds, (supplierId) => {
            if (companySuppliersChanges[supplierId]?.id)
              return updateSupplierAgent(companySuppliersChanges[supplierId]);

            if (companySuppliersChanges[supplierId] && !companySuppliersChanges[supplierId]?.id)
              return createSupplierAgent({ ...companySuppliersChanges[supplierId], company_id: company.id });

            const userSupplierId = _.find(company.suppliers, { id: +supplierId })?.SupplierAgent?.id

            if (userSupplierId)
              return deleteSupplierAgent(userSupplierId)
          }).filter(Boolean),
          ..._.map(companyInvoiceChanges, (invoice) => updateInvoice(invoice)),
          ..._.map(companyExpenseChanges, (expense) => updateExpense(expense)),
        ]);
      })
      .then(refreshEntities)
      .then(() => {
        enqueueSnackbar(t("company-updated"), { variant: 'success' });
      })
      .catch((e) => {
        enqueueSnackbar(t("something-went-wrong"), { variant: 'error' });
        throw e;
      })
      .finally(() => {
        setShowLoader(false);
      })
  }

  const createClientHandler = (client) => {
    setShowLoader(true);
    return createClient(client)
      .then(() => {
        setSelectedClient(null)
        refreshEntities();
      })
      .then(() => {
        enqueueSnackbar(t("client-created"), { variant: 'success' });
      })
      .catch(() => {
        enqueueSnackbar(t("something-went-wrong"), { variant: 'error' });
      })
      .finally(() => {
        setShowLoader(false);
      })
  }

  const updateClientHandler = (client, clientAgentsChanges) => {
    setShowLoader(true);

    return updateClient(client)
      .then(() => {
        if (!clientAgentsChanges.length)
          return;

        return Promise.all(_.map(clientAgentsChanges, (agent) => {
          if (!agent.id)
            return createClientAgent(agent);

          if (agent.delete)
            return deleteClientAgent(agent.id)
        }).filter(Boolean))
      })
      .then(refreshEntities)
      .then(() => {
        enqueueSnackbar(t("client-updated"), { variant: 'success' });
      })
      .catch((e) => {
        enqueueSnackbar(t("something-went-wrong"), { variant: 'error' });
        throw e;
      })
      .finally(() => {
        setShowLoader(false);
      })
  }

  const createSupplierHandler = (supplier) => {
    setShowLoader(true);
    return createSupplier(supplier)
      .then(() => {
        setSelectedSupplier(null)
        refreshEntities();
      })
      .then(() => {
        enqueueSnackbar(t("supplier-created"), { variant: 'success' });
      })
      .catch(() => {
        enqueueSnackbar(t("something-went-wrong"), { variant: 'error' });
      })
      .finally(() => {
        setShowLoader(false);
      })
  }

  const updateSupplierHandler = (supplier, supplierAgentsChanges) => {
    setShowLoader(true);

    return updateSupplier(supplier)
      .then(() => {
        if (!supplierAgentsChanges.length)
          return;

        return Promise.all(_.map(supplierAgentsChanges, (agent) => {
          if (!agent.id)
            return createSupplierAgent(agent);

          if (agent.delete)
            return deleteSupplierAgent(agent.id)
        }).filter(Boolean))
      })
      .then(refreshEntities)
      .then(() => {
        enqueueSnackbar(t("supplier-updated"), { variant: 'success' });
      })
      .catch((e) => {
        enqueueSnackbar(t("something-went-wrong"), { variant: 'error' });
        throw e;
      })
      .finally(() => {
        setShowLoader(false);
      })
  }

  return <div className="employees-page-wrapper">
    <header className="employees-header">
      {!selectedUser && !selectedCompany && !selectedClient && !selectedSupplier && <div>
        <div className="employees-header-full-width">
          <div className="employees-header-full-width-title">{t('accounts')}</div>

          <div>
            <YeulsButton onClick={() => setShowPushConfirmDialog(true)} variant="outlined"
                         className="push-button">{t("push-agents")}</YeulsButton>
            <YeulsButton onClick={() => setShowMergeAgentsConfirmDialog(true)} variant="outlined"
            >{t("sync-agents")}</YeulsButton>
            <YeulsButton onClick={() => setShowMergeDocsConfirmDialog(true)} variant="outlined"
                         className="sync-button">{t("sync-docs")}</YeulsButton>
            <YeulsButton onClick={() => setShowMergeStorageDocsConfirmDialog(true)} variant="outlined"
                         className="sync-button">{t("sync-storage-docs")}</YeulsButton>
          </div>
        </div>
      </div>}
      {selectedUser && <div>
        <div className="employees-header-left-part" onClick={() => setSelectedUser(null)}>
          <ArrowBackIcon/> {selectedUser.id ? selectedUser.firstname : t("create-user")}
        </div>
        {selectedUser?.id && <div className="employees-header-right-part">
          <YeulsButton variant="outlined"
                       onClick={() => {setShowTransformConfirmDialog(true)}}>{t("transform-to-company")}</YeulsButton>

          <TextField
            variant="outlined"
            select
            value={!!selectedUser.active}
            onChange={(e) => {
              if (e.target.value !== selectedUser.active)
                updateUserHandler({ id: selectedUser.id, active: e.target.value });
            }}
            className={`employees-page-status-input ${!!selectedUser.active ? 'active' : 'dismissed'}`}
          >
            <MenuItem key={Status.active} value={true}>{t(Status.active)}</MenuItem>
            <MenuItem key={Status.dismissed} value={false}>{t(Status.dismissed)}</MenuItem>
          </TextField>
        </div>}
      </div>}
      {selectedCompany && <div>
        <div className="employees-header-left-part" onClick={() => setSelectedCompany(null)}>
          <ArrowBackIcon/> {selectedCompany.id ? selectedCompany.company_name : t("create-company")}
        </div>
      </div>}
      {selectedClient && <div>
        <div className="employees-header-left-part" onClick={() => setSelectedClient(null)}>
          <ArrowBackIcon/>{selectedClient.id ? selectedClient.client_name : t("create-client")}
        </div>
      </div>}
      {selectedSupplier && <div>
        <div className="employees-header-left-part" onClick={() => setSelectedSupplier(null)}>
          <ArrowBackIcon/>{selectedSupplier.id ? selectedSupplier.supplier_name : t("create-supplier")}
        </div>
      </div>}
    </header>
    {showLoader && <Loader/>}
    {!showLoader && !selectedUser && !selectedCompany && !selectedClient && !selectedSupplier && <>
      <div className="employees-tabs-wrapper">
        <YeulsTabs
          config={tabsConfig}
          onChange={(value) => {
            setFilterValue("");
            setCurrentTabId(value)
          }}/>
        {currentTabId === tabIds.agentsList &&
        <YeulsButton onClick={() => setSelectedUser({})}>{t("create-user")}</YeulsButton>}
        {currentTabId === tabIds.companiesList &&
        <YeulsButton
          onClick={() => setSelectedCompany({})}>{t("create-company")}</YeulsButton>}
        {currentTabId === tabIds.clientsList &&
        <YeulsButton
          onClick={() => setSelectedClient({})}>{t("create-client")}</YeulsButton>}
        {currentTabId === tabIds.suppliersList &&
        <YeulsButton
          onClick={() => setSelectedSupplier({})}>{t("create-supplier")}</YeulsButton>}
      </div>
      <div className={"search-wrapper"}>
        <Search value={filterValue} onChange={setFilterValue} onClearFilter={() => setFilterValue("")}/>
      </div>
      {currentTabId === tabIds.agentsList && updateTable &&
      <div className={'tab-content-wrapper'}>
        <AgentsList users={filteredUsers} setSelectedUser={setSelectedUser}/>
      </div>}
      {currentTabId === tabIds.companiesList && updateTable &&
      <div className={'tab-content-wrapper'}>
        <CompaniesList companies={filteredCompanies} setSelectedCompany={setSelectedCompany}/></div>}
      {currentTabId === tabIds.clientsList && updateTable && <div className={'tab-content-wrapper'}>
        <ClientsList clients={filteredClients} setSelectedClient={setSelectedClient}/>
      </div>}
      {currentTabId === tabIds.suppliersList && updateTable && <div className={'tab-content-wrapper'}>
        <SuppliersList suppliers={filteredSuppliers} setSelectedSupplier={setSelectedSupplier}/>
      </div>}
    </>}
    {selectedUser && !selectedUser?.id &&
    <NewUser
      onCreateUser={createUserHandler}
      onCancel={() => setSelectedUser(null)}
      companies={companies}
      clients={clients}
      suppliers={suppliers}
    />}
    {selectedUser && selectedUser?.id &&
    <UserDetails user={selectedUser} companies={companies} clients={clients} suppliers={suppliers}
                 onUpdateUser={updateUserHandler}/>}
    {selectedCompany && !selectedCompany?.id &&
    <NewCompany
      onCreateCompany={createCompanyHandler}
      onCancel={() => setSelectedCompany(null)}
      users={users}
      clients={clients}
      suppliers={suppliers}
    />}
    {selectedCompany && selectedCompany?.id &&
    <CompanyDetails company={selectedCompany} onUpdateCompany={updateCompanyHandler} users={users} clients={clients}
                    suppliers={suppliers}/>}

    {selectedClient && !selectedClient?.id &&
    <NewClient
      onCreateClient={createClientHandler}
      onCancel={() => setSelectedClient(null)}
      users={users}
      companies={companies}
    />}
    {selectedClient && selectedClient?.id &&
    <ClientDetails client={selectedClient} onUpdateClient={updateClientHandler} users={users} companies={companies}/>}
    {selectedSupplier && !selectedSupplier?.id &&
    <NewSupplier
      onCreateSupplier={createSupplierHandler}
      onCancel={() => setSelectedSupplier(null)}
      users={users}
      companies={companies}
    />}
    {selectedSupplier && selectedSupplier?.id &&
    <SupplierDetails supplier={selectedSupplier} onUpdateSupplier={updateSupplierHandler} users={users}
                     companies={companies}/>}
    <ConfirmDialog
      open={showMergeAgentsConfirmDialog}
      onClose={() => setShowMergeAgentsConfirmDialog(null)}
      title={t('sync-agents')}
      onSubmit={() => {
        setShowLoader(true);
        setShowMergeAgentsConfirmDialog(false);
        mergeAgents()
          .then(() => refreshEntities())
          .then(() => {
            enqueueSnackbar(t("agents-merged-successfully"), { variant: 'success' });
          })
          .catch(() => {
            enqueueSnackbar(t("something-went-wrong"), { variant: 'error' });
          })
          .finally(() => {
            setShowLoader(false);
          })
      }}>
      <div className="dialog-content">{t('sure-sync')}</div>
    </ConfirmDialog>

    <ConfirmDialog
      open={showMergeDocsConfirmDialog}
      onClose={() => setShowMergeDocsConfirmDialog(null)}
      title={t('sync-docs')}
      onSubmit={() => {
        setShowLoader(true);
        setShowMergeDocsConfirmDialog(false);
        mergeDocs()
          .then(() => refreshEntities())
          .then(() => {
            enqueueSnackbar(t("docs-merged-successfully"), { variant: 'success' });
          })
          .catch(() => {
            enqueueSnackbar(t("something-went-wrong"), { variant: 'error' });
          })
          .finally(() => {
            setShowLoader(false);
          })
      }}>
      <div className="dialog-content">{t('sure-sync')}</div>
    </ConfirmDialog>

    <ConfirmDialog
      open={showMergeStorageDocsConfirmDialog}
      onClose={() => setShowMergeStorageDocsConfirmDialog(null)}
      title={t('sync-storage-docs')}
      onSubmit={() => {
        setShowLoader(true);
        setShowMergeStorageDocsConfirmDialog(false);
        syncStorageDocuments()
          .then(() => refreshEntities())
          .then(() => {
            enqueueSnackbar(t("docs-merged-successfully"), { variant: 'success' });
          })
          .catch(() => {
            enqueueSnackbar(t("something-went-wrong"), { variant: 'error' });
          })
          .finally(() => {
            setShowLoader(false);
          })
      }}>
      <div className="dialog-content">{t('sure-sync')}</div>
    </ConfirmDialog>

    <ConfirmDialog
      open={showPushConfirmDialog}
      onClose={() => setShowPushConfirmDialog(null)}
      title={t('push-agents')}
      onSubmit={() => {
        setShowLoader(true);
        setShowPushConfirmDialog(false);
        pushAgents()
          .then(() => refreshEntities())
          .then(() => {
            enqueueSnackbar(t("agents-pushed-successfully"), { variant: 'success' });
          })
          .catch(() => {
            enqueueSnackbar(t("something-went-wrong"), { variant: 'error' });
          })
          .finally(() => {
            setShowLoader(false);
          })
      }}>
      <div className="dialog-content">{t('sure-push')}</div>
    </ConfirmDialog>

    <ConfirmDialog
      open={showTransformConfirmDialog}
      onClose={() => setShowTransformConfirmDialog(null)}
      title={t('transform-to-company')}
      onSubmit={() => {
        setShowLoader(true);
        setShowTransformConfirmDialog(false);

        transformUserToCompany(selectedUser.id)
          .then(() => {
            setSelectedUser(null);
            return refreshEntities();
          })
          .then(() => {
            enqueueSnackbar(t("agents-transformed-successfully"), { variant: 'success' });
          })
          .catch(() => {
            enqueueSnackbar(t("something-went-wrong"), { variant: 'error' });
          })
          .finally(() => {
            setShowLoader(false);
          })
      }}>
      <div className="dialog-content">{t('sure-transform')}</div>
    </ConfirmDialog>
  </div>
}

export default Accounts;