import React, { useMemo, useState } from "react";

import "./NewClient.scss";
import { useTranslation } from "react-i18next";
import { validateGeneralClientForm } from "../../common-components/validators";
import ProgressIndicator from "../../common-components/progress-indicator";
import YeulsButton from "../../../yeuls-button";
import GeneralClientForm from "../../common-components/forms/general-client-form";
import BankForm from "../../common-components/forms/bank-form";
import ClientAgentsList from "./client-agents-list";

const defaultClientFormData = {
  client_name: { value: null, error: false },
  country: { value: null, error: false },
  city: { value: null, error: false },
  address: { value: null, error: false },
  zip: { value: null, error: false },
  phone: { value: null, error: false },
  email: { value: null, error: false },
  business_number: { value: null, error: false },
  withholding_tax: { value: null, error: false },
  withholding_tax_expiration: { value: null, error: false },
  general_ledger: { value: null, error: false },
  created_by: { value: null, error: false },
  included_vat: { included_vat: true, error: false },
  currency: { value: null, error: false },

  bank_name: { value: null, error: false },
  bank_number: { value: null, error: false },
  bank_branch_number: { value: null, error: false },
  bank_account_number: { value: null, error: false },
}
const steps = {
  general: 1,
  bank: 2,
  agents: 3
}

const NewClient = (props) => {
  const { onCreateClient, onCancel, users, companies } = props;
  const { t, i18n } = useTranslation();
  const stepsConfig = useMemo(() => [
      { value: steps.general, label: t("general"), validate: validateGeneralClientForm },
      { value: steps.bank, label: t("bank") },
      { value: steps.agents, label: t("agents") },
    ],
    // eslint-disable-next-line
    [i18n.language]);
  const [activeStep, setActiveStep] = useState(stepsConfig[0]);
  const [clientFormData, setClientFormData] = useState(defaultClientFormData);
  const [clientAgents, setClientAgents] = useState([]);

  const nextStep = () => {
    if (activeStep.value !== steps.agents) {
      if (!activeStep.validate || ( activeStep.validate, activeStep.validate(clientFormData, setClientFormData) ))
        setActiveStep(stepsConfig[activeStep.value]);
    } else {
      saveClient();
    }
  }

  const saveClient = () => {
    const client = {
      client_name: clientFormData.client_name.value,
      country: clientFormData.country.value,
      city: clientFormData.city.value,
      address: clientFormData.address.value,
      zip: clientFormData.zip.value,
      phone: clientFormData.phone.value,
      email: clientFormData.email.value,
      business_number: clientFormData.business_number.value,
      withholding_tax: +clientFormData.withholding_tax.value,
      withholding_tax_expiration: clientFormData.withholding_tax_expiration.value,
      general_ledger: clientFormData.general_ledger.value,
      created_by: clientFormData.created_by.value,
      included_vat: clientFormData.included_vat.value,
      currency: clientFormData.currency.value,

      bank_name: clientFormData.bank_name.value,
      bank_number: clientFormData.bank_number.value,
      bank_branch_number: clientFormData.bank_branch_number.value,
      bank_account_number: clientFormData.bank_account_number.value,
      clientAgents
    };

    onCreateClient(client);
  }

  return <div className="new-client-wrapper">
    <div className="new-client-indicator-wrapper">
      <ProgressIndicator steps={stepsConfig} activeStep={activeStep} setActiveStep={setActiveStep}/>
    </div>
    <div className="new-client-form-container">
      {activeStep?.value === steps.general &&
      <GeneralClientForm generalFormData={clientFormData} setGeneralFormData={setClientFormData}/>}
      {activeStep?.value === steps.bank &&
      <BankForm bankFormData={clientFormData} setBankFormData={setClientFormData}/>}
      {activeStep?.value === steps.agents &&
      <ClientAgentsList users={users} companies={companies} clientAgents={clientAgents} setClientAgents={setClientAgents}/>}
    </div>
    <div className="new-client-buttons-container">
      <YeulsButton variant="negative" onClick={onCancel}>{t("cancel")}</YeulsButton>
      <YeulsButton onClick={nextStep}>{t("next")}</YeulsButton>
    </div>
  </div>
}

export default NewClient